import React, { useEffect, useRef } from "react";

const Team = () => {
  // Animation code
  const contentRef = useRef(null);
  const itemRefs = useRef([]);

  useEffect(() => {
    const observerItems = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-on-scroll");
          }
        });
      },
      { root: null, rootMargin: "0px", threshold: 0.1 }
    );

    itemRefs.current.forEach((itemRef) => {
      if (itemRef) {
        observerItems.observe(itemRef);
      }
    });

    const content = contentRef.current;
    const observerContent = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          content.classList.add("animate-on-scroll");
        }
      });
    });

    observerContent.observe(content);

    return () => {
      observerItems.disconnect();
      observerContent.disconnect();
    };
  }, []);

  return (
    <>
      <section id="team" className=" team" ref={contentRef}>
        <style>
          {`
          .team{
          background: rgb(244,244,244);
background: linear-gradient(145deg, rgba(244,244,244,1) 0%, rgba(254,115,6,0.4682247899159664) 100%);
          }
          
         
        `}
        </style>
        <div
          className="container section-title "
          data-aos="fade-up"
          ref={(el) => (itemRefs.current[0] = el)}
        >
          <h2>Team</h2>
          <p>
            Meet our proactive team member spearheading our online book rental
            service.
          </p>
        </div>

        <div className="container">
          <div className="row gy-5">
            <div
              className="col-lg-4 col-md-6 member "
              data-aos="fade-up"
              data-aos-delay="100"
              ref={(el) => (itemRefs.current[1] = el)}
            >
              <div className="member-img">
                <img
                  src="assets/img/team/Prakash.png"
                  className="img-fluid"
                  alt=""
                />
                <div className="social">
                  <a href="#">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>
              <div className="member-info text-center">
                <h4>Prakash Gupta</h4>
                <span>Corporate Team</span>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 member "
              data-aos="fade-up"
              data-aos-delay="200"
              ref={(el) => (itemRefs.current[2] = el)}
            >
              <div className="member-img">
                <img
                  src="assets/img/team/shweta.png"
                  className="img-fluid"
                  alt=""
                />
                <div className="social">
                  <a href="#">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>
              <div className="member-info text-center">
                <h4>Shweta Magar</h4>
                <span>Platform Team</span>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 member "
              data-aos="fade-up"
              data-aos-delay="300"
              ref={(el) => (itemRefs.current[3] = el)}
            >
              <div className="member-img">
                <img
                  src="assets/img/team/Rishi_singh.png"
                  className="img-fluid"
                  alt=""
                />
                <div className="social">
                  <a href="#">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>
              <div className="member-info text-center">
                <h4>Rishi Singh</h4>
                <span>Sales Team</span>
              </div>
            </div>

            {/* <div className="col-lg-4 col-md-6 member " data-aos="fade-up" data-aos-delay="400" ref={(el) => (itemRefs.current[4] = el)}>
              <div className="member-img">
                <img src="assets/img/team/team-4.jpg" className="img-fluid" alt="" />
                <div className="social">
                  <a href="#"><i className="bi bi-twitter"></i></a>
                  <a href="#"><i className="bi bi-facebook"></i></a>
                  <a href="#"><i className="bi bi-instagram"></i></a>
                  <a href="#"><i className="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div className="member-info text-center">
                <h4>Amanda Jepson</h4>
                <span>Accountant</span>
              </div>
            </div> */}
            {/* 
            <div className="col-lg-4 col-md-6 member " data-aos="fade-up" data-aos-delay="500" ref={(el) => (itemRefs.current[5] = el)}>
              <div className="member-img">
                <img src="assets/img/team/team-5.jpg" className="img-fluid" alt="" />
                <div className="social">
                  <a href="#"><i className="bi bi-twitter"></i></a>
                  <a href="#"><i className="bi bi-facebook"></i></a>
                  <a href="#"><i className="bi bi-instagram"></i></a>
                  <a href="#"><i className="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div className="member-info text-center">
                <h4>Brian Doe</h4>
                <span>Marketing</span>
                <p>Qui consequuntur quos accusamus magnam quo est molestiae eius laboriosam sunt doloribus quia impedit laborum velit</p>
              </div>
            </div>

            

    <div class="col-lg-4 col-md-6 member " data-aos="fade-up" data-aos-delay="600"  ref={(el) => (itemRefs.current[6] = el)}>
      <div class="member-img">
        <img src="assets/img/team/team-6.jpg" class="img-fluid" alt=""/>
        <div class="social">
          <a href="#"><i class="bi bi-twitter"></i></a>
          <a href="#"><i class="bi bi-facebook"></i></a>
          <a href="#"><i class="bi bi-instagram"></i></a>
          <a href="#"><i class="bi bi-linkedin"></i></a>
        </div>
      </div>
      <div class="member-info text-center">
        <h4>Josepha Palas</h4>
        <span>Operation</span>
        <p>Sint sint eveniet explicabo amet consequatur nesciunt error enim rerum earum et omnis fugit eligendi cupiditate vel</p>
      </div>
    </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
