import React from "react";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import PlanDetails from "./membershipsection/PlanDetails";
import MembershipMessages from "./membershipsection/MembershipMessages";

const Membership = () => {
  const location = useLocation();
  const isAboutPage = location.pathname === "/membership";
  return (
    <>
      <Header isAboutPage={isAboutPage} />
      <div className="container-fluid mt-3">
        {/* <h1 className="membership-heading">Membership Plan</h1> */}
        <div className="pricing-section">
          <h2 className="h2 text-black">Plans & pricing</h2>
          <PlanDetails />
          <MembershipMessages/>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Membership;
