import React, { useEffect, useRef, useState } from 'react';

const Contact = () => {
  const contactRef = useRef(null);
  const itemRefs = useRef([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-on-scroll");
        }
      });
    }, { root: null, rootMargin: "0px", threshold: 0.1 });

    itemRefs.current.forEach((itemRef) => {
      if (itemRef) {
        observer.observe(itemRef);
      }
    });

    return () => {
      itemRefs.current.forEach((itemRef) => {
        if (itemRef) {
          observer.unobserve(itemRef);
        }
      });
    };
  }, []);

  useEffect(() => {
    if (window.location.hash === '#contact') {
      const element = document.getElementById('contact');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    try {
      const response = await fetch('https://kalptaruonline.com/php_api/contact_api.php', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setPopupMessage(data.message);
        setShowPopup(true);
        form.reset();
      } else {
        setPopupMessage('Oops, there was an error sending your message. Please try again later.');
        setShowPopup(true);
      }
    } catch (error) {
      setPopupMessage('Oops, there was an error sending your message. Please try again later.');
      setShowPopup(true);
    }
  };

  return (
    <section id="contact" className="contact" ref={contactRef}>
      <style>
        {`
          .contact .info-item {
            height: 100%;
          }
          .popup {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #FFF;
            padding: 40px;
            border-radius: 8px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            z-index: 999;
            text-align: center;
            animation: popupAnimation 0.5s ease-in-out;
          }
          .popup h3 {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 20px;
            color: var(--accent-color);
          }
          .popup button {
            background-color: var(--accent-color);
            color: #FFF;
            border: none;
            padding: 10px 20px;
            border-radius: 4px;
            cursor: pointer;
            font-size: 16px;
            transition: background-color 0.3s ease;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }
          .popup button:hover {
            background-color: brown;
          }
          @keyframes popupAnimation {
            0% {
              opacity: 0;
              transform: translate(-50%, -60%);
            }
            100% {
              opacity: 1;
              transform: translate(-50%, -50%);
            }
          }
        `}
      </style>
      <div className="container section-title" data-aos="fade-up">
        <h2>Get in Touch</h2>
        <p>We're here to help. Feel free to reach out to us with any questions or inquiries.</p>
      </div>
      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4">
          <div className="col-lg-6">
            <div className="row gy-4">
              {/* Address */}
              <div className="col-md-6" ref={(el) => (itemRefs.current[0] = el)}>
                <div className="info-item" data-aos="fade" data-aos-delay="200">
                  <i className="bi bi-geo-alt"></i>
                  <h3>Address</h3>
                  <p>La Vida Loca, Pimple Saudagar</p>
                  <p>Pune, MH 411027</p>
                </div>
              </div>
              {/* Call Us */}
              <div className="col-md-6" ref={(el) => (itemRefs.current[1] = el)}>
                <div className="info-item" data-aos="fade" data-aos-delay="300">
                  <i className="bi bi-telephone"></i>
                  <h3>Call Us</h3>
                  <p>+91 9545890389</p>
                  {/* <p>+1 6678 254445 41</p> */}
                </div>
              </div>
              {/* Email Us */}
              <div className="col-md-6" ref={(el) => (itemRefs.current[2] = el)}>
                <div className="info-item" data-aos="fade" data-aos-delay="400">
                  <i className="bi bi-envelope"></i>
                  <h3>Email Us</h3>
                  <p>support@kalptaruonline.com</p>
                  {/* <p>contact@example.com</p> */}
                </div>
              </div>
              {/* Open Hours */}
              <div className="col-md-6" ref={(el) => (itemRefs.current[3] = el)}>
                <div className="info-item" data-aos="fade" data-aos-delay="500">
                  <i className="bi bi-clock"></i>
                  <h3>Open Hours</h3>
                  <p>Monday - Saturday</p>
                  <p>9:00AM - 05:00PM</p>
                </div>
              </div>
            </div>
          </div>
          {/* Contact Form */}
          <div className="col-lg-6">
            <form onSubmit={handleSubmit} className="php-email-form no-bg" data-aos="fade-up" data-aos-delay="200">
              <div className="row gy-4">
                <div className="col-md-6">
                  <input type="text" name="name" className="form-control" placeholder="Your Name" required />
                </div>
                <div className="col-md-6 ">
                  <input type="email" className="form-control" name="email" placeholder="Your Email" required />
                </div>
                <div className="col-md-12">
                  <input type="text" className="form-control" name="subject" placeholder="Subject" required />
                </div>
                <div className="col-md-12">
                  <textarea className="form-control" name="message" rows="6" placeholder="How can we assist you?" required></textarea>
                </div>
                <div className="col-md-12 text-center">
                  <button type="submit">Send Message</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="popup">
          <h3>{popupMessage}</h3>
          <button onClick={() => setShowPopup(false)}>Close</button>
        </div>
      )}
    </section>
  );
};

export default Contact;