import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import Header from "../components/Header";
import FooterN from "../components/FooterN";
import AboutSection from "../components/AboutSection";
import HeaderN from "../components/HeaderN";
import Preloader from "../components/Preloader";
import useScrolledClass from "../components/useScrolledClass";
import LoginCard from "../components/login/LoginCard";
import WhatsAppIcon from "../components/StickyWhatsapp/WhatsappIcon";

const About = () => {
  const location = useLocation();
  const isAboutPage = location.pathname === "/about";
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    if (showModal) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }

    return () => {
      body.style.overflow = "auto";
    };
  }, [showModal]);
  useScrolledClass();
  return (
    <>
      <div className={showModal ? "blur" : ""}>
        <HeaderN isAboutPage={isAboutPage} toggleModal={toggleModal} />

        <AboutSection />
        <FooterN />
      </div>
      <Preloader />
      {showModal && (
        <div className="modal-overlay modal-overlay-bg">
          <LoginCard toggleModal={toggleModal} />
        </div>
      )}
      <WhatsAppIcon/>
    </>
  );
};

export default About;
