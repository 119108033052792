import React from "react";
import whatsappLogo from "../assets/images/icon/WhatsApp-Logo.png";

const FooterN = () => {
  return (
    <>
      <footer id="footer" class="footer pb-0">
        <div class="container footer-top">
          <div class="row gy-4">
            <div class="col-lg-4 col-md-12 footer-about">
              <a href="" class="logo-container d-flex align-items-center">
                <img
                  src="assets/img/logo-wb.svg"
                  alt="Kalptaru"
                  class="img-fluid h-100"
                />
              </a>
              <p>
                Kalptaru: Your Online Book Rental Destination. Browse, rent, and
                enjoy a vast collection of books conveniently from the comfort
                of your home. Experience the joy of reading without the
                commitment of ownership.
              </p>
              <div class="social-links d-flex mt-4">
                <a href="">
                  <i class="bi bi-twitter-x"></i>
                </a>
                <a href="">
                  <i class="bi bi-facebook"></i>
                </a>
                <a href="">
                  <i class="bi bi-instagram"></i>
                </a>
                <a href="">
                  <i class="bi bi-linkedin"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-2 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="/#about">About us</a>
                </li>
                <li>
                  <a href="/#faq">FAQ</a>
                </li>
                <li>
                  <a href="#">Terms of service</a>
                </li>
                <li>
                  <a href="#">Privacy policy</a>
                </li>
              </ul>
            </div>

            <div class="col-lg-3 footer-contact text-md-start">
              <h4>Contact Us</h4>
              <p>La Vida Loca, Pimple Saudagar</p>
              <p>Pune, MH 411027</p>
              <p>India</p>
              <p class="mt-4">
                <strong>Phone:</strong> <span>+91 9545890389</span>
              </p>
              <p>
                <strong>Email:</strong> <span>support@kalptaruonline.com</span>
              </p>
            </div>

            <div class="col-lg-3 footer-links">
              <h4>Stay in Touch</h4>
              <p className="stay-in-touch-info">
                To be updated with all the latest news, offers, and special
                announcements
              </p>
              <div className="footer-column">
                <div className="newsletter">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="newsletter-input"
                  />
                  <button className="newsletter-button">Subscribe</button>
                </div>
                <div className="whatsapp">
                  <a
                    href="https://wa.me/yourwhatsapplink"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={whatsappLogo}
                      alt="WhatsApp"
                      className="whatsapp-logo"
                    />

                    <p className="whatsapp-text">Reach out to us on WhatsApp</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid copyright text-center mt-4">
          <p>
            &copy; <span>Copyright 2024 </span>{" "}
            <strong class="px-1 text-accent">Kalptaru</strong>{" "}
            <span>All Rights Reserved</span>
          </p>

          <p>
            {" "}
            Developed by{" "}
            <a href="https://www.mejona.in">Mejona Techhnology LLP </a>{" "}
          </p>
        </div>
      </footer>
    </>
  );
};

export default FooterN;
