import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const DonateBookForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    city: '',
    state: '',
    address: '',
    numBooks: 1,
    books: [{ title: '', author: '', cost: '' }],
  });

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'numBooks') {
      const numBooks = value === '' ? 1 : parseInt(value);
      const newBooks = Array(numBooks).fill({ title: '', author: '', cost: '' });
      setFormData({ ...formData, [name]: numBooks, books: newBooks });
    } else if (name.includes('book')) {
      const [field, index, prop] = name.split('.');
      const newBooks = [...formData.books];
      newBooks[index][prop] = value;
      setFormData({ ...formData, books: newBooks });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/donate-book', formData);
      setModalMessage('Thank you. We will get in touch with you.');
      setShowModal(true);
    } catch (error) {
      setModalMessage('An error occurred. Please try again.');
      setShowModal(true);
    }
  };

  const incrementNumBooks = () => {
    setFormData({
      ...formData,
      numBooks: formData.numBooks + 1,
      books: [
        ...formData.books,
        { title: '', author: '', cost: '' },
      ],
    });
  };

  const decrementNumBooks = () => {
    if (formData.numBooks > 1) {
      setFormData({
        ...formData,
        numBooks: formData.numBooks - 1,
        books: formData.books.slice(0, -1),
      });
    }
  };

  return (
    <Container className="">
      <h2 className="text-center mb-4" style={{borderBottom: '1px solid #fe7306'}}>Donate a Book</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col md={6}>
            <Form.Group controlId="formPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col md={6}>
            <Form.Group controlId="formCity">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formState">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="formAddress" className='mt-3'>
          <Form.Label>Address</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formNumBooks" className='mt-3'>
          <Form.Label>Number of Books to Donate</Form.Label>
          <div className="d-flex align-items-center">
            <Button variant="secondary" onClick={decrementNumBooks} disabled={formData.numBooks === 1}>
              -
            </Button>
            <Form.Control
              type="number"
              name="numBooks"
              value={formData.numBooks}
              onChange={handleChange}
              min="1"
              className="mx-2"
              style={{ width: '80px' }}
              required
            />
            <Button variant="secondary" onClick={incrementNumBooks}>
              +
            </Button>
          </div>
        </Form.Group>
        <br/>
        {formData.books.map((book, index) => (
          <div key={index} className="mb-4">
            <h5>Book {index + 1}</h5>
            <Row>
              <Col md={4}>
                <Form.Group controlId={`formBookTitle-${index}`}>
                  <Form.Label>Book Title</Form.Label>
                  <Form.Control
                    type="text"
                    name={`book.${index}.title`}
                    value={book.title}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId={`formBookAuthor-${index}`}>
                  <Form.Label>Author</Form.Label>
                  <Form.Control
                    type="text"
                    name={`book.${index}.author`}
                    value={book.author}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId={`formBookCost-${index}`}>
                  <Form.Label>Cost</Form.Label>
                  <Form.Control
                    type="number"
                    name={`book.${index}.cost`}
                    value={book.cost}
                    onChange={handleChange}
                    min="0"
                    step="0.01"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        ))}
        <Button variant="primary" type="submit" className="mx-auto d-block">
          Submit
        </Button>
      </Form>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Response</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default DonateBookForm;