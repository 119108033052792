import { useEffect, useRef } from 'react';

const useAnimateOnScroll = (options = {}) => {
  const observerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-on-scroll');
          observer.unobserve(entry.target);
        }
      });
    }, options);

    observerRef.current = observer;

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [options]);

  const observeElement = (element) => {
    if (observerRef.current && element) {
      observerRef.current.observe(element);
    }
  };

  return observeElement;
};

export default useAnimateOnScroll;