import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import logo from "../assets/images/Sanskrit.png";

const Header = ({ isAboutPage }) => {
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <>
      <div className="container-fluid header-n">
        <div className="d-flex flex-row" id="nav-container">
          <div className="logo-container">
            <a href="/">
              <img id="logo" src={logo} alt="Logo" />
            </a>
          </div>
          <div className="d-flex flex-column rightnavsection">
            <div className="search-navbar-together">
              <div></div>
              <div className="search-container">
                <div className="search">
                  <input
                    type="text"
                    className="search__input"
                    placeholder="Search for Books, Author, Pulishers"
                  />
                  <button className="search__button">
                    <svg
                      className="search__icon"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                    >
                      <g>
                        <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
              <div className="navbar-links">
                <a id="faqDesktop" href="login">
                  <i class="fa-solid fa-user"></i>
                  &nbsp; Login
                </a>
              </div>
            </div>
            <nav
              className="navbar navbar-expand-lg navbar-scroll shadow-0"
              style={{ backgroundColor: "#fe7306" }}
            >
              <div className="container-fluid">
                <button
                  className="navbar-toggler ps-0"
                  type="button"
                  onClick={toggleCollapse}
                  aria-controls="navbarExample01"
                  aria-expanded={!isCollapsed}
                  aria-label="Toggle navigation"
                  id="menu-button"
                >
                  <span className="d-flex justify-content-start align-items-center">
                    <i className="fas fa-bars"></i>
                  </span>
                </button>
                <div
                  className={`collapse navbar-collapse ${
                    isCollapsed ? "" : "show"
                  }`}
                  id="navbarExample01"
                >
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item active">
                      <a
                        className="nav-link px-3"
                        href="/"
                        style={{
                          color: location.pathname === "/" ? "#522b1c" : "",
                        }}
                      >
                        Home
                      </a>
                    </li>
                    <li className="nav-item active">
                      <a
                        className="nav-link px-3"
                        href="howitworks"
                        style={{
                          color:
                            location.pathname === "/howitworks"
                              ? "#522b1c"
                              : "",
                        }}
                      >
                        How It Works?
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link px-3"
                        href="membership"
                        style={{
                          color:
                            location.pathname === "/membership"
                              ? "#522b1c"
                              : "",
                        }}
                      >
                        Membership
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link px-3" href="#!">
                        Be A Partner
                      </a>
                    </li>
                    <li className="nav-item active">
                      <a
                        className="nav-link px-3"
                        href="donate"
                        style={{
                          color:
                            location.pathname === "/donate" ? "#522b1c" : "",
                        }}
                      >
                        Donate Books
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link px-3" href="#!">
                        KT Community
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link px-3"
                        href="faq"
                        style={{
                          color: location.pathname === "/faq" ? "#522b1c" : "",
                        }}
                      >
                        FAQ
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link px-3"
                        href="about"
                        style={{
                          color:
                            location.pathname === "/about" ? "#522b1c" : "",
                        }}
                      >
                        About Us
                      </a>
                    </li>
                  </ul>

                  {/* <ul className="navbar-nav flex-row">
                    <li className="nav-item">
                      <a className="nav-link pe-3" href="#!">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link px-3" href="#!">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link ps-3" href="#!">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul> */}
                </div>
                <div className="navbar-links login-mobile-container">
                  <a id="login-mobile" href="login">
                    <i class="fa-solid fa-user"></i>
                    &nbsp; Login
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
