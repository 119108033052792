import React, { useState } from "react";
import DonateBookForm from "./DonateBookForm";

const DonateContent = () => {
  const [showForm, setShowForm] = useState(false);

  const handleDonateNowClick = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <div className="donate-book-container">
      <div className="donate-header">
        <h1>Donate Books, Share Knowledge</h1>
        <p>
          Help others by donating your books and spreading the joy of reading.
        </p>
      </div>
      <div className="donate-content">
        <div className="donate-section">
          <h2 style={{fontSize: '4rem',color: '#fe7306',fontFamily: 'monospace'}}>Why Donate?</h2>
          <p style={{fontSize: 'x-large'}}>
            By donating books, you give others the opportunity to learn and
            grow. Your old books could be someone else's treasure, helping them
            expand their knowledge and imagination.
          </p>
        </div>
        {/* <div className="donate-process-section">
          <h2>How It Works</h2>
          <div className="donate-process-steps">
            <div className="donate-step">
              <span className="donate-step-number">1</span>
              <p>Collect books you no longer need.</p>
            </div>
            <div className="donate-step">
              <span className="donate-step-number">2</span>
              <p>Fill out the donation form by clicking the "Donate Now" button below. </p>
            </div>
            <div className="donate-step">
              <span className="donate-step-number">3</span>
              <p>We will get in touch with you and schedule a pickup date according to your convenience.</p>
            </div>
          </div>
        </div> */}
        {/* <div className="donate-cta-section">
          <h2>Ready to Donate?</h2>
          <button className="donate-button" onClick={handleDonateNowClick}>Donate Now</button>
        </div> */}

        <DonateBookForm />
      </div>
      {/* {showForm && (
        <div className="donate-form-modal">
          <div className="donate-form">
            <span className="close-button" onClick={handleCloseForm}><img style={{width:'50px'}} src='imagespu/icon/cross.png' /></span>
            <DonateBookForm/>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default DonateContent;
