import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
// import "glightbox/dist/css/glightbox.min.css";
import "./index.css";
import "./assets/styles/custom.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import FAQ from "./pages/FAQ";
import Login from "./pages/Login";
import Membership from "./components/Membership";
import HowItWorksN from "./pages/HowItWorksN";
import NotFound from "./pages/NotFound";
import Donate from "./pages/Donate";
import TestComponents from "./components/TestComponents";
// import HeadComponent from "./components/HeadComponent";

function App() {
  return (
    <>
      <Router>
        <Routes>
          {/* Use Routes instead of Switch */}
          <Route exact path="/" element={<Home />} />{" "}
          {/* Use element prop instead of component */}
          <Route path="/about" element={<About />} />{" "}
          {/* Use element prop instead of component */}
          <Route path="/contact" element={<Contact />} />{" "}
          {/* Use element prop instead of component */}
          <Route path="/faq" element={<FAQ />} />{" "}
          {/* Use element prop instead of component */}
          <Route path="/login" element={<Login />} />{" "}
          {/* Use element prop instead of component */}
          <Route path="/signup" element={<Login />} />
          <Route path="/howitworks" element={<HowItWorksN />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/test" element={<TestComponents />} />
          <Route path="*" element={<NotFound />} />{" "}
          {/* Use path="*" for 404 routes */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
