import React, { useState } from "react";
import DiscountPrice from "../DiscountPrice";

const PlanDetails = () => {
  const [isYearly, setIsYearly] = useState(false);

  const handleToggleChange = () => {
    setIsYearly(!isYearly);
  };

  return (
    <div className="pricing-page">
      <div className="pricing-page__toggle-container">
        <span>Monthly</span>
        <label className="pricing-page__toggle-switch">
          <input
            type="checkbox"
            checked={isYearly}
            onChange={handleToggleChange}
          />
          <span className="pricing-page__toggle-slider"></span>
        </label>
        <span>Yearly</span>
      </div>
      {/* {isYearly && (
        <span className="pricing-page__discount mb-3">Get 1 Month FREE</span>
      )} */}

      <div className="pricing-page__pricing-cards">
        <PricingCard
          title="Solo Reader"
          description="Tailored picks for individual enjoyment."
          price='151'
          checkIsYearly={isYearly ? true : false}
          // priceInterval={isYearly ? " per year" : " per month"}
          buttonText="Start with Solo Reader"
          features={[
            "Limited core library of 5,000+ Books",
            "1 Book at a time",
            "Deposit : ₹501",
          ]}
        />
        <PricingCard
          title="Casual reader"
          description=" Diverse selections for relaxed reading"
          price='251'
          checkIsYearly={isYearly ? true : false}

          // priceInterval={isYearly ? " per year" : " per month"}
          buttonText="Start with Casual Reader"
          features={[
            "Limited core library of 5,000+ Books",
            "2 Books at a time",
            "Deposit : ₹1001",
          ]}
        />
        <PricingCard
          title="Leisure Reader"
          description="Abundant choices for leisurely exploration"
          price='501'
          checkIsYearly={isYearly ? true : false}
          // priceInterval={isYearly ? " per year" : " per month"}
          buttonText="Start with Leisure Reader"
          isBestOffer
          features={[
            "Limited core library of 5,000+ Books",
            "4 Books at a time",
            "Deposit : ₹2001",
          ]}
        />
      </div>
    </div>
  );
};

const PricingCard = ({
  title,
  description,
  price,
  priceInterval,
  buttonText,
  isBestOffer,
  features,
  checkIsYearly,
}) => {
  return (
    <div
      className={`pricing-card__card ${
        isBestOffer ? "pricing-card__best-offer" : ""
      }`}
    >
      <div className="pricing-card__header">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
     

      {checkIsYearly != true ? (
      <div className="pricing-card__price">
        <span>₹</span>
       
          <span>{price}</span>

        <span className="price-interval">{priceInterval}</span>
      </div>
      ): (
        <DiscountPrice amount={price}/>
        )}
      <button className="pricing-card__button">{buttonText}</button>
      {/* <p className="pricing-card__free-trial">or start a FREE trial</p> */}
      <div className="pricing-card__features">
        {/* <h4>
          Includes{" "}
          {isBestOffer
            ? "everything in Standard and more:"
            : "Standard includes:"}
        </h4> */}
        <ul>
          {features.map((feature, index) => (
            <li key={index}>
              <span className="pricing-card__checkmark">&#10003;</span>{" "}
              {feature}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PlanDetails;
