import React, { useState } from "react";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import Signup from "./Signup";

const styles = {
  hidden: {
    display: "none",
  },
};

const LoginCard = ({ toggleModal }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [activeComponent, setActiveComponent] = useState("login"); // Track the active component

  const handleCloseModal = () => {
    setIsModalOpen(false);
    toggleModal(); // Call the toggleModal function passed as a prop
  };

  const switchToSignup = () => {
    setActiveComponent("signup");
  };

  const switchToForgotPassword = () => {
    setActiveComponent("forgotPassword");
  };

  const switchToLogin = () => {
    setActiveComponent("login");
  };

  return isModalOpen ? (
    <div className="login-card">
      <button
        className="gclose gbtn close-btn fs-1"
        tabIndex="2"
        aria-label="Close"
        style={{ color: "black", right: "20px", width: "35px" }}
        onClick={handleCloseModal}
      >
        ×
      </button>
      <div className="card-body">
        <div id="loginbox">
          <div className="inline-inner">
            <div
              className="container section-title p-0"
              style={{ maxWidth: "90px", marginBottom: "20px" }}
              data-aos="fade-up"
            >
              <img
                id="logo-color"
                src="assets/img/logo-c.svg"
                alt="Kalptaru"
                className="img-fluid w-100"
              />
            </div>
            <div className="row gy-4">
              <div className="col-md-12">
                {activeComponent === "login" && <Login switchToSignup={switchToSignup} switchToForgotPassword={switchToForgotPassword} styles={styles} />}
                {activeComponent === "signup" && <Signup styles={styles} switchToLogin={switchToLogin} />}
                {activeComponent === "forgotPassword" && <ForgotPassword styles={styles} switchToLogin={switchToLogin} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default LoginCard;
