import React, { useState, useEffect, useRef } from "react";
import HeaderN from "../components/HeaderN";
import useScrolledClass from "../components/useScrolledClass";
import FooterN from "../components/FooterN";
import Preloader from "../components/Preloader";
import LoginCard from "../components/login/LoginCard";
import WhatsAppIcon from "../components/StickyWhatsapp/WhatsappIcon";

const HowItWorksN = () => {
  useScrolledClass();

  const elementRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-on-scroll");
          }
        });
      },
      { rootMargin: "0px 0px -100px 0px" }
    );

    elementRefs.current.forEach((el) => observer.observe(el));

    return () => {
      elementRefs.current.forEach((el) => observer.unobserve(el));
    };
  }, []);

  const addToRefs = (el) => {
    if (el && !elementRefs.current.includes(el)) {
      elementRefs.current.push(el);
    }
  };
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    if (showModal) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }

    return () => {
      body.style.overflow = "auto";
    };
  }, [showModal]);
  return (
    <>
    <div className={showModal ? "blur" : ""}>

      <HeaderN toggleModal={toggleModal} />
      <main id="main">
        <section className="banners howitworks">
          <div className="banner-item">
            <img
              className="banner-img"
              src="assets/img/howitworks-header.jpg"
              alt=""
              data-aos="fade-in"
            />
            <div
              className="d-flex align-items-center justify-content-center h-100"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="container text-center">
                <div>
                  <h2
                    className="banner-header-text opacity"
                    data-aos="fade-up"
                    data-aos-delay="100"
                    ref={addToRefs}
                  >
                    How it Works
                  </h2>
                </div>
                <p data-aos="fade-up" data-aos-delay="200">
                  <i className="bi bi-quote quote-icon-left"></i>
                  <span className="opacity" ref={addToRefs}>
                    {" "}
                    Nurturing Minds, Cultivating futures{" "}
                  </span>
                  <i className="bi bi-quote quote-icon-right"></i>
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="about">
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row align-items-xl-center gy-5">
              <div className="col-xl-5 content opacity" ref={addToRefs}>
                <h3 ref={addToRefs}>The 4R Concept</h3>
                <h2 ref={addToRefs}>Concept that a reader loves</h2>
                <p className="opacity" ref={addToRefs}>
                  <b className="text-accent">KalpTaru</b> is dedicated to
                  fostering a love for reading among youth, drawing inspiration
                  from ancient Indian traditions of learning. Through curated
                  book selections and innovative programs, we aim to cultivate
                  critical thinking and creativity, empowering individuals...
                </p>
              </div>

              <div className="col-xl-7">
                <div className="row gy-4 icon-boxes">
                  <div
                    className="col-md-6 opacity"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    ref={addToRefs}
                  >
                    <div className="icon-box opacity" ref={addToRefs}>
                      <i className="bi bi-phone"></i>
                      <h3>Request</h3>
                      <p>Bringing the magic of literature at your home</p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 opacity"
                    data-aos="fade-up"
                    data-aos-delay="300"
                    ref={addToRefs}
                  >
                    <div className="icon-box">
                      <i className="bi bi-house-check"></i>
                      <h3>Receive</h3>
                      <p>Discovering new reads delivered right at your door</p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 opacity"
                    data-aos="fade-up"
                    data-aos-delay="400"
                    ref={addToRefs}
                  >
                    <div className="icon-box">
                      <i className="bi bi-book"></i>
                      <h3>Read</h3>
                      <p>Where dreams take root and mind blossom</p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 opacity"
                    data-aos="fade-up"
                    data-aos-delay="400"
                    ref={addToRefs}
                  >
                    <div className="icon-box">
                      <i className="bi bi-cart-check"></i>
                      <h3>Return</h3>
                      <p>Where dreams take root and mind blossom</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="team mission">
          <div
            className="container section-title opacity"
            data-aos="fade-up"
            ref={addToRefs}
          >
            <h2>Process Details</h2>
          </div>

          <div className="container">
            <div className="row opacity" ref={addToRefs}>
              <div className="col-8 mx-auto position-relative">
                <div className="main-timeline">
                  <div className="timeline opacity" ref={addToRefs}>
                    <div className="timeline-content">
                      <div className="timeline-year opacity" ref={addToRefs}>
                        <span>
                          <i className="bi bi-person-badge"></i>
                        </span>
                      </div>

                      <h3 className="title">Create your Account</h3>
                      <p className="description">
                        Begin by registering your personal account to access our
                        platform.
                      </p>
                    </div>
                  </div>
                  <div
                    className="timeline opacity"
                    data-aos="fade-up"
                    ref={addToRefs}
                    data-aos-delay="200"
                  >
                    <div className="timeline-content">
                      <div className="timeline-year">
                        <span>
                          <i className="bi bi-cart-check"></i>
                        </span>
                      </div>

                      <h3 className="title">Browse and Add to Cart</h3>
                      <p className="description">
                        Explore books and effortlessly add your favorites to
                        your cart.
                      </p>
                    </div>
                  </div>
                  <div
                    className="timeline opacity"
                    ref={addToRefs}
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="timeline-content">
                      <div className="timeline-year">
                        <span>
                          <i className="bi bi-door-open"></i>
                        </span>
                      </div>

                      <h3 className="title">Delivery Information</h3>
                      <p className="description">
                        Input necessary details for a smooth book delivery
                        process.
                      </p>
                    </div>
                  </div>
                  <div
                    className="timeline opacity"
                    ref={addToRefs}
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <div className="timeline-content">
                      <div className="timeline-year">
                        <span>
                          <i className="bi bi-check2-all"></i>
                        </span>
                      </div>

                      <h3 className="title opacity" ref={addToRefs}>
                        Checkout
                      </h3>
                      <p className="description">
                        Finalize your rental by confirming your selections and
                        completing the order.
                      </p>
                    </div>
                  </div>
                  <div
                    className="timeline opacity"
                    ref={addToRefs}
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <div className="timeline-content">
                      <div className="timeline-year">
                        <span>
                          <i className="bi bi-bag-check"></i>
                        </span>
                      </div>

                      <h3 className="title">Receive package</h3>
                      <p className="description">
                        Receive your order at your doorstep.
                      </p>
                    </div>
                  </div>
                  <div
                    className="timeline opacity"
                    ref={addToRefs}
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <div className="timeline-content">
                      <div className="timeline-year">
                        <span>
                          <i className="bi bi-book"></i>
                        </span>
                      </div>

                      <h3 className="title">Read at your Convenience</h3>
                      <p className="description">
                        Read the books at your Convenience.
                      </p>
                    </div>
                  </div>
                  <div
                    className="timeline opacity"
                    ref={addToRefs}
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <div className="timeline-content">
                      <div className="timeline-year">
                        <span>
                          <i className="bi bi-bootstrap-reboot"></i>
                        </span>
                      </div>

                      <h3 className="title">Request Return</h3>
                      <p className="description">
                        Finished Reading, request a return pickup.
                      </p>
                    </div>
                  </div>
                  <div
                    className="timeline opacity"
                    ref={addToRefs}
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <div className="timeline-content">
                      <div className="timeline-year">
                        <span>
                          <i className="bi bi-cart4"></i>
                        </span>
                      </div>

                      <h3 className="title">Reorder</h3>
                      <p className="description">Reorder your fresh set.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about">
          <div
            className="container section-title opacity"
            ref={addToRefs}
            data-aos="fade-up"
          >
            <h2>A Watch</h2>
          </div>

          <div className="container opacity" ref={addToRefs}>
            <div className="row">
              <div className="col-8 mx-auto position-relative">
                <video
                  width="100%"
                  poster="assets/img/books/video_thumb.jpg"
                  controls
                >
                  <source src="assets/videos/howitworks.mp4" type="video/mp4" />
                  <source src="assets/videos/howitworks.ogv" type="video/ogg" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </section>

        <section className="team mission">
          <div className="container section-title opacity" ref={addToRefs}>
            <h2>Book Delivery Guidelines</h2>
          </div>

          <div className="container opacity" ref={addToRefs}>
            <div className="row align-items-xl-center gy-5">
              <div className="col-xl-9 mx-auto content">
                <ol>
                  <li>
                    Delivery of books begins once your Membership Payment is
                    cleared.
                  </li>
                  <li>
                    You can keep the books with you as long as you want,
                    provided you continue to be a paying member i.e. you keep
                    paying the monthly rental.
                  </li>
                  <li>
                    Do not get too cozy with the books and unintentionally cause
                    any damage to them. You might have to pay for the books in
                    that case.
                  </li>
                  <li>
                    Make sure that you order for a pickup only after you have
                    finished reading the book.
                  </li>
                  <li>
                    Make sure that either you or anybody on your behalf is
                    available at your delivery address to receive and return the
                    books.
                  </li>
                  <li>
                    Membership is automatically renewed unless cancelled before
                    expiry. For details please see FAQ.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
      </main>
      <FooterN />
      </div>
      <Preloader />
      {showModal && (
        <div className="modal-overlay modal-overlay-bg">
          <LoginCard toggleModal={toggleModal} />
        </div>
      )}
      <WhatsAppIcon/>
    </>
  );
};

export default HowItWorksN;
