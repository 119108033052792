import React from "react";
import Header from "../components/Header";
import DonateContent from "../components/donatebook/DonateContent";
import Footer from '../components/Footer';

const Donate = () => {
  return (
    <>
      <Header />
      <div className="container-fluid mt-3">
        <div className="bg">
          <DonateContent />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Donate;
