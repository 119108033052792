import { useEffect } from 'react';

const useScrolledClass = () => {
  useEffect(() => {
    const handleScroll = () => {
      const selectBody = document.querySelector('body');
      const whiteLogo = document.querySelector('#logo-white');
      const colorLogo = document.querySelector('#logo-color');
      const selectHeader = document.querySelector('#header');

      if (!selectHeader || !selectHeader.classList.contains('scroll-up-sticky') && !selectHeader.classList.contains('sticky-top') && !selectHeader.classList.contains('fixed-top')) return;

      const scrolled = window.scrollY > 100;

      if (scrolled) {
        selectBody.classList.add('scrolled');
        whiteLogo?.classList.add('hide-img');
        whiteLogo?.classList.remove('show-img');
        colorLogo?.classList.add('show-img');
        colorLogo?.classList.remove('hide-img');
      } else {
        selectBody.classList.remove('scrolled');
        whiteLogo?.classList.add('show-img');
        whiteLogo?.classList.remove('hide-img');
        colorLogo?.classList.add('hide-img');
        colorLogo?.classList.remove('show-img');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
};

export default useScrolledClass;