import React, { useState, useEffect, useRef } from 'react';
import './pricing.css';

const Membership = () => {
  const [isYearly, setIsYearly] = useState(false);
  const handleToggle = () => {
    setIsYearly(!isYearly);
  };

  // Animation code
  const contentRef = useRef(null);
  const itemRefs = useRef([]);
  const pricingItemRefs = useRef([]);

  useEffect(() => {
    const observerItems = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-on-scroll");
        }
      });
    }, { root: null, rootMargin: "0px", threshold: 0.1 });

    itemRefs.current.forEach((itemRef) => {
      if (itemRef) {
        observerItems.observe(itemRef);
      }
    });

    const content = contentRef.current;
    const observerContent = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          content.classList.add("animate-on-scroll");
        }
      });
    });

    observerContent.observe(content);

    const handleMouseEnter = (e) => {
      const hoveredItem = e.currentTarget;
      
      // Add 'featured' class to the hovered item
      hoveredItem.classList.add('featured');
      
      // Remove 'featured' class from other pricing items
      pricingItemRefs.current.forEach((pricingItemRef) => {
        if (pricingItemRef !== hoveredItem) {
          pricingItemRef.classList.remove('featured');
        }
      });
    };
    

    pricingItemRefs.current.forEach((pricingItemRef) => {
      pricingItemRef.addEventListener('mouseenter', handleMouseEnter);
    });

    return () => {
      observerItems.disconnect();
      observerContent.disconnect();
      pricingItemRefs.current.forEach((pricingItemRef) => {
        pricingItemRef.removeEventListener('mouseenter', handleMouseEnter);
      });
    };
  }, []);

  useEffect(() => {
    if (window.location.hash === '#pricing') {
      const element = document.getElementById('pricing');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <section id="pricing" className="pricing " ref={contentRef}>
      <div className="container section-title" data-aos="fade-up">
        <h2>Plans & Pricing</h2>
        <div className="price-toggle">
          <div className="switch-button">
            <input
              className="switch-button-checkbox"
              type="checkbox"
              onChange={handleToggle}
              checked={isYearly}
            ></input>
            <label className="switch-button-label" htmlFor="">
              <span className="switch-button-label-span">Monthly</span>
            </label>
          </div>
        </div>
      </div>
      <div className="container" data-aos="zoom-in" data-aos-delay="100">
        <div className="row g-4">
          {/* Solo Reader */}
          <div className="col-lg-4 " ref={(el) => (itemRefs.current[0] = el)}>
            <div className="pricing-item" ref={(el) => (pricingItemRefs.current[0] = el)}>
              <h3>Solo Reader</h3>
              <p className="text-center">Tailored picks for individual enjoyment.</p>
              <div className="icon">
                <i className="bi bi-box"></i>
              </div>
              <h4>
              <span style={{textDecoration: 'line-through'}}>{isYearly ? "₹" : ''} {isYearly ? 151 * 12 : ''}</span>
                <sup>₹</sup>
                {isYearly ? 151 * 11 : 151}
                <span> / {isYearly ? "year" : "month"}</span>
              </h4>
              {isYearly ? <h6 style={{textAlign:'center'}}>Save 10%**</h6> : null}

              <ul>
                <li>
                  <i className="bi bi-check"></i> <span>Limited core library of 5,000+ Books</span>
                </li>
                <li>
                  <i className="bi bi-check"></i> <span>1 Book at a  time</span>
                </li>
                <li>
                  <i className="bi bi-check"></i> <span>Deposit : ₹501</span>
                </li>
              </ul>
              <div className="text-center">
                <a href="#" className="buy-btn">
                  Subscribe
                </a>
              </div>
            </div>
          </div>
          {/* Casual Reader */}
          <div className="col-lg-4 " ref={(el) => (itemRefs.current[1] = el)}>
            <div className="pricing-item featured" ref={(el) => (pricingItemRefs.current[1] = el)}>
              <h3>Casual Reader</h3>
              <p className="text-center">Diverse selections for relaxed reading</p>
              <div className="icon">
                <i className="bi bi-rocket"></i>
              </div>
              <h4>
              <span style={{textDecoration: 'line-through'}}>{isYearly ? "₹" : ''} {isYearly ? 251 * 12 : ''}</span>

                <sup>₹</sup>
                {isYearly ? 251 * 12 -251 : 251}
                <span> / {isYearly ? "year" : "month"}</span>
              </h4>
              {isYearly ? <h6 style={{textAlign:'center'}}>Save 10%**</h6> : null}

              <ul>
                <li>
                  <i className="bi bi-check"></i> <span>Limited core library of 5,000+ Books</span>
                </li>
                <li>
                  <i className="bi bi-check"></i> <span>2 Books at a time</span>
                </li>
                <li>
                  <i className="bi bi-check"></i> <span>Deposit : ₹1001</span>
                </li>
              </ul>
              <div className="text-center">
                <a href="#" className="buy-btn">
                  Subscribe
                </a>
              </div>
            </div>
          </div>
          {/* Leisure Reader */}
          <div className="col-lg-4 " ref={(el) => (itemRefs.current[2] = el)}>
            <div className="pricing-item" ref={(el) => (pricingItemRefs.current[2] = el)}>
              <h3>Leisure Reader</h3>
              <p className="text-center">Abundant choices for leisurely exploration</p>
              <div className="icon">
                <i className="bi bi-send"></i>
              </div>
              <h4>
              <span style={{textDecoration: 'line-through'}}>{isYearly ? "₹" : ''} {isYearly ? 501 * 12 : ''}</span>
                <sup>₹</sup>
                {isYearly ? 501 * 12 - 501: 501}
                <span> / {isYearly ? "year" : "month"}</span>
                
              </h4>
              {isYearly ? <h6 style={{textAlign:'center'}}>Save 10%**</h6> : null}

              
              <ul>
                <li>
                  <i className="bi bi-check"></i> <span>Limited core library of 5,000+ Books</span>
                </li>
                <li>
                  <i className="bi bi-check"></i> <span>4 Books at a time</span>
                </li>
                <li>
                  <i className="bi bi-check"></i> <span>Deposit : ₹2001</span>
                </li>
              </ul>
              <div className="text-center">
                <a href="#" className="buy-btn">
                  Subscribe
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Membership;