import React from 'react';
import './Modal.css';

const Modal = ({ isOpen, onClose, videoUrl }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <button className="close-btn" onClick={onClose}>
        <i className="bi bi-x"></i>
      </button>
      <div className="modal-content">
        <div className="video-container">
          <video controls autoPlay className="video-player">
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default Modal;
