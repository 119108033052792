import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

const TestComponents = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: '',
    agreesToPrivacyPolicy: false,
    subscribeToNewsletter: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
  };

  return (
    <Container>
     <h2 className="mt3 h2">Contact Us</h2>
      <div className="contact-form">
        <p>(*) Asterisk denotes mandatory fields</p>
      <Form onSubmit={handleSubmit}>
        <Row style={{marginBottom:'20px'}}>
          <Col>
            <Form.Group controlId="formFirstName">
              <Form.Label>First name*</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formLastName">
              <Form.Label>Last name*</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formEmail">
              <Form.Label>Email*</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone number</Form.Label>
              <Form.Control
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="formMessage">
          <Form.Label>How can we help you?</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="formPrivacyPolicy">
          <Form.Check
            type="checkbox"
            name="agreesToPrivacyPolicy"
            label={
              <span>
                I agree to the <a href="#">privacy policy</a>
              </span>
            }
            checked={formData.agreesToPrivacyPolicy}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="formSubscribeNewsletter">
          <Form.Check
            type="checkbox"
            name="subscribeToNewsletter"
            label="Subscribe me to Kalptaru's latest thought leadership, blogs and updates."
            checked={formData.subscribeToNewsletter}
            onChange={handleChange}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
        <p>You can also email us directly at support@kalptaruonline.com</p>
      </Form>
      </div>
    </Container>
  );
};

export default TestComponents;