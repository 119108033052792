import React from "react";

const Signup = ({ styles, switchToLogin }) => {
  return (
    <form
      action="forms/contact.php"
      method="post"
      className="login-form"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <div className="row gy-4">
        <div className="col-md-12">
          <input
            type="text"
            name="fname"
            className="form-control"
            placeholder="Full Name"
            required
          />
        </div>
        <div className="col-md-12">
          <select className="form-control" name="gender" required>
            <option value="" disabled selected>
              Select Gender
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div className="col-md-12">
          <input
            type="email"
            name="email"
            className="form-control"
            placeholder="Email"
            required
          />
        </div>
        <div className="col-md-12">
          <input
            type="tel"
            name="phone"
            className="form-control"
            placeholder="Phone number"
            required
          />
        </div>

        <div className="col-md-12">
          <input
            type="password"
            className="form-control"
            name="password"
            placeholder="Password"
            required
          />
        </div>

        <div className="col-md-12 text-center">
          <div style={styles.hidden} className="loading">
            Loading
          </div>
          <div style={styles.hidden} className="error-message"></div>
          <div style={styles.hidden} className="sent-message">
            Signed up Successfully!, Hold on for a moment
          </div>

          <button type="submit">Sign up</button>
        </div>

        <div className="col-md-12 text-center">
          <div>
            Already have an Account?{" "}
            <a
              href="#"
              className="link-opacity-100-hover fs-14px"
              onClick={switchToLogin}
            >
              Login
            </a>
          </div>
        </div>

        <div className="col-md-12 text-center">
          <button type="button" className="btn btn-dark rounded-pill">
            <i className="bi bi-apple fs-5"></i> Signup with Apple
          </button>
        </div>

        <div className="col-md-12 text-center">
          <button type="button" className="btn btn-google rounded-pill">
            <i className="bi bi-google fs-5"></i> Signup with Google{" "}
          </button>
        </div>
      </div>
    </form>
  );
};

export default Signup;
