import React, { useEffect, useRef, useState } from "react";
import GlightboxInit from "glightbox";
import "glightbox/dist/css/glightbox.min.css";
import "./Booklist.css";
import { ImageList, ImageListItem, useMediaQuery } from '@mui/material';

const BookList = () => {
  const categoryRef = useRef(null);
  const [activeFilter, setActiveFilter] = useState("*");

  // Define media queries for different breakpoints
  const isXl = useMediaQuery('(min-width:1200px)');
  const isLg = useMediaQuery('(min-width:992px)');
  const isMd = useMediaQuery('(min-width:768px)');
  const isSm = useMediaQuery('(min-width:250px)');

  // Determine the number of columns based on the current viewport size
  let cols = 4; // Default to 4 columns for extra large screens
  if (isXl) {
    cols = 4;
  } else if (isLg) {
    cols = 3;
  } else if (isMd) {
    cols = 2;
  } else if (isSm) {
    cols = 1;
  }

  const BookList = [
    {
      image: "/assets/img/books/001.jpg",
      title: "Thirst",
      author: "Varsha Bajaj",
      category: ".filter-app",
    },
    {
      image: "/assets/img/books/002.jpg",
      title: "Thirst",
      author: "Varsha Bajaj",
      category: ".filter-app",
    },
    {
      image: "/assets/img/books/003.jpg",
      title: "Thirst",
      author: "Varsha Bajaj",
      category: ".filter-product",
    },
    {
      image: "/assets/img/books/004.jpg",
      title: "Thirst",
      author: "Varsha Bajaj",
      category: ".filter-branding",
    },
    {
      image: "/assets/img/books/005.jpg",
      title: "Thirst",
      author: "Varsha Bajaj",
      category: ".filter-app",
    },
    {
      image: "/assets/img/books/006.jpg",
      title: "Thirst",
      author: "Varsha Bajaj",
      category: ".filter-product",
    },
    {
      image: "/assets/img/books/007.jpg",
      title: "Thirst",
      author: "Varsha Bajaj",
      category: ".filter-branding",
    },
    {
      image: "/assets/img/books/008.jpg",
      title: "Thirst",
      author: "Varsha Bajaj",
      category: ".filter-app",
    },
  ]

  useEffect(() => {
    if (categoryRef.current) {
      const lightbox = GlightboxInit({
        selector: ".glightbox",
        skin: "clean",
        openEffect: "zoom",
        closeEffect: "fade",
        descPosition: "right",
        zoomable: false,
      });

      return () => {
        lightbox.destroy();
      };
    }
  }, [categoryRef]);

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
  };

  return (
    <section id="category" className="category" ref={categoryRef}>
      <div className="container section-title">
        <h2>Reader's Choice</h2>
      </div>

      <div className="container">
        <div className="isotope-layout" data-default-filter="*" data-layout="masonry" data-sort="original-order">
          <div className="category-filters isotope-filters d-flex justify-content-center">
            <button className={`btn mx-2 ${activeFilter === "*" ? "active btn-accent" : ""}`} onClick={() => handleFilterChange("*")}>
              All
            </button>
            <button className={`btn mx-2 ${activeFilter === ".filter-app" ? "active btn-accent" : ""}`} onClick={() => handleFilterChange(".filter-app")}>
              Award winning
            </button>
            <button className={`btn mx-2 ${activeFilter === ".filter-product" ? "active btn-accent" : ""}`} onClick={() => handleFilterChange(".filter-product")}>
              Mystery
            </button>
            <button className={`btn mx-2 ${activeFilter === ".filter-branding" ? "active btn-accent" : ""}`} onClick={() => handleFilterChange(".filter-branding")}>
              Thriller
            </button>
          </div>

          <div className="row gy-4 isotope-container">
            <ImageList variant="masonry" cols={cols} gap={10}>
              {BookList.filter((book) => activeFilter === "*" || book.category === activeFilter).map((book, index) => (
                <BookItem key={index} image={book.image} title={book.title} author={book.author} />
              ))}
            </ImageList>
          </div>
        </div>
      </div>
    </section>
  );
};

const BookItem = ({ image, title, author }) => {
  return (
    <div className="category-item isotope-item filter-app">
      <ImageListItem>
        <img srcSet={`${image}?w=124&fit=crop&auto=format&dpr=2 2x`} src={`${image}?w=124&fit=crop&auto=format`} alt={""} loading="lazy" className="img-fluid" />
        <div className="category-info d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex flex-column">
            <h4>{title}</h4>
            <p>{author}</p>
          </div>
          <a href={image} title="Life's Amazing Secrets" data-gallery="category-gallery-app" className="glightbox btn btn-accent fs-4 rounded-circle" data-glightbox="description: .custom-desc1; descPosition: right; zoomable: false">
            <i className="bi bi-eye"></i>
          </a>

          <div className="glightbox-desc custom-desc1 category-info flex-grow-1">
            <h4>{title}</h4>
            <p>{author}</p>
            <p>
              Stop going through life,
              <br />
              Start growing through life!
            </p>
            <p>
              While navigating their way through Mumbai's horrendous traffic, Gaur Gopal Das and his wealthy young friend Harry get talking, delving into concepts ranging from the human condition to finding one's purpose in life and the key to lasting happiness.
            </p>
            <p>
              Whether you are looking at strengthening your relationships, discovering your true potential, understanding how to do well at work or even how you can give back to the world, Gaur Gopal Das takes us on an unforgettable journey with his precious insights on these areas of life.
            </p>
            <p>
              Das is one of the most popular and sought-after monks and life coaches in the world, having shared his wisdom with millions. His debut book, Life's Amazing Secrets, distils his experiences and lessons about life into a light-hearted, thought-provoking book that will help you align yourself with the life you want to live.
            </p>

            <a href="" className="btn btn-accent mt-5">Rent Now</a>
          </div>
        </div>
      </ImageListItem>
    </div>
  );
};

export default BookList;