import React from 'react';

const AppDownload = () => {
  return (
    <section id="call-to-action" className="call-to-action">
      <img src="assets/img/cta-bg.jpg" alt="" />
      <div className="container">
        <div className="row justify-content-center" data-aos="zoom-in" data-aos-delay="100">
          <div className="col-xl-10">
            <div className="text-center">
              <h3>Download our Mobile App Now</h3>
              <p>Get instant access to thousands of books on the go. Rent and read your favorite titles anytime, anywhere.</p>
              <a className="cta-btn rounded-pill" href="#"><i className="bi bi-google-play"></i> Google Play</a>
              <a className="cta-btn rounded-pill" href="#"><i className="bi bi-apple"></i> App Store</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppDownload;
