import React from 'react';
import './WhatsappIcon.css';

const WhatsAppIcon = () => {
  const handleWhatsAppRedirect = () => {
    // Replace the following number with your desired WhatsApp number
    const whatsappNumber = '+15512259406';
    const predefinedMessage = 'I would like to inquire about the Kalptaru';
    const whatsappUri = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(predefinedMessage)}`;
    window.open(whatsappUri, '_blank');
  };

  return (
    <div className="whatsapp-icon" onClick={handleWhatsAppRedirect}>
      <i className="fab fa-whatsapp"></i>
    </div>
  );
};

export default WhatsAppIcon;