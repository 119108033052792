import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./herosection.css";

const HeroSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    prevArrow: null,
    nextArrow: null,
  };

  const contentRef = useRef(null);

  useEffect(() => {
    const content = contentRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          content.classList.add("animate-on-scroll");
        }
      });
    });

    observer.observe(content);

    return () => {
      observer.unobserve(content);
    };
  }, []);

  return (
    <div className="hero-section-container">
      <div ref={contentRef}>
        <Slider {...settings} style={{ height: "100vh" }}>
          <div className="banner-item">
            <div className="banner-overlay"></div>
            <div className="banner-content">
              <h2>Welcome to Kalptaru</h2>
              <p>
                <i className="bi bi-quote quote-icon-left"></i>
                <span>A room without books is like a body without a soul.</span>
                <i className="bi bi-quote quote-icon-right"></i>
              </p>
            </div>
            <img
              className="banner-img"
              src="assets/img/hero-bg-b.jpg"
              alt=""
              data-aos="fade-in"
            />
            <div className="banner-dots"></div>
          </div>
          <div className="banner-item">
            <div className="banner-overlay"></div>
            <div className="banner-content">
              <h2>Welcome to Kalptaru</h2>
              <p>
                <i className="bi bi-quote quote-icon-left"></i>
                <span>
                  Books are the quietest and most constant of friends; they are
                  the most accessible and wisest of counsellors, and the most
                  patient of teachers. - Charles.W.Eliot
                </span>
                <i className="bi bi-quote quote-icon-right"></i>
              </p>
            </div>
            <img
              className="banner-img"
              src="assets/img/hero-bg-1.jpg"
              alt=""
              data-aos="fade-in"
            />
            <div className="banner-dots"></div>
          </div>
          <div className="banner-item">
            <div className="banner-overlay"></div>
            <div className="banner-content">
              <h2>Welcome to Kalptaru</h2>
              <p>
                <i className="bi bi-quote quote-icon-left"></i>
                <span>
                  Reading is to the mind what exercise is to the body. - Joseph
                  Addison
                </span>
                <i className="bi bi-quote quote-icon-right"></i>
              </p>
            </div>
            <img
              className="banner-img"
              src="assets/img/hero-bg-2.jpg"
              alt=""
              data-aos="fade-in"
            />
            <div className="banner-dots"></div>
          </div>
          <div className="banner-item">
            <div className="banner-overlay"></div>
            <div className="banner-content">
              <h2>Welcome to Kalptaru</h2>
              <p>
                <i className="bi bi-quote quote-icon-left"></i>
                <span>
                  Enjoy the convenience of books delivered to your doorstep.
                </span>
                <i className="bi bi-quote quote-icon-right"></i>
              </p>
            </div>
            <img
              className="banner-img"
              src="assets/img/hero-bg-3.jpg"
              alt=""
              data-aos="fade-in"
            />
            <div className="banner-dots"></div>
          </div>{" "}
        </Slider>
      </div>
    </div>
  );
};

export default HeroSection;
