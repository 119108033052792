import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

const HeaderN = ({ toggleModal }) => {
  const styles = {
    logoContainer: {
      position: 'relative',
      width: '115px',
      height: '80px',
    },
  };

  const [isMobileNavActive, setIsMobileNavActive] = React.useState(false);
  const navRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const body = document.body;
    if (isMobileNavActive) {
      body.classList.add("mobile-nav-active");
    } else {
      body.classList.remove("mobile-nav-active");
    }
  }, [isMobileNavActive]);

  const toggleMobileNav = () => {
    setIsMobileNavActive(!isMobileNavActive);
  };

  const closeMobileNav = () => {
    setIsMobileNavActive(false);
  };

  const handleLinkClick = (event) => {
    if (isMobileNavActive && navRef.current.contains(event.target)) {
      closeMobileNav();
    }
  };

  const isHomePage = location.pathname === "/";

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="container-fluid d-flex align-items-center justify-content-between">
        <a href="/" className="logo d-flex align-items-center me-auto me-xl-0 px-5">
          <div className="logo-container" style={styles.logoContainer}>
            <img id="logo-white" src="assets/img/logo-wa.svg" alt="Kalptaru" className="img-fluid w-100 show-img"/>
            <img id="logo-color" src="assets/img/logo-c.svg" alt="Kalptaru" className="img-fluid w-100 hide-img" />
          </div>
        </a>
        <nav
          id="navmenu"
          className={`navmenu ${isMobileNavActive ? "mobile-nav-active" : ""} ms-auto pe-5`}
          ref={navRef}
          onClick={handleLinkClick}
        >
          <ul>
            <li>
              <a href={isHomePage ? "#about" : "/about"}>
                About Us
              </a>
            </li>
            <li>
              <a href={isHomePage ? "#howitworks" : "/howitworks"}>
                How It Works?
              </a>
            </li>
            <li>
              <a href={isHomePage ? "#pricing" : "/#pricing"}>Membership</a>
            </li>
            <li>
              <a href={isHomePage ? "#donate" : "/#donate"}>
                Donate Books
              </a>
            </li>
            <li>
              <a href={isHomePage ? "#category" : "/#category"}>
              Reader's Choice
              </a>
            </li>
            <li>
              <a href={isHomePage ? "#be-a-partner" : "/#be-a-partner"}>
              Be a Partner
              </a>
            </li>
            <li>
              <a href={isHomePage ? "#faq" : "/faq"}>
                FAQ
              </a>
            </li>
            <li>
              <a href={isHomePage ? "#contact" : "/#contact"}>
                Contact
              </a>
            </li>
          </ul>
          <i className="mobile-nav-toggle d-xl-none bi bi-list" onClick={toggleMobileNav}></i>
        </nav>

        <a onClick={toggleModal} className="btn-getstarted loginbox" data-glightbox="width: 450; height: auto;" href="#login">Login</a>
      </div>
    </header>
  );
};

export default HeaderN;
