import React, { useEffect, useRef, useState } from 'react';
import "./Howworks.css";
import Modal from '../../components/homepage/Modal_video/Modal';

const Howworks = () => {
  const sectionRef = useRef(null);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const section = sectionRef.current;
    const items = section.querySelectorAll('[data-aos]');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-on-scroll");
        }
      });
    }, { threshold: 0.2 });

    items.forEach(item => {
      observer.observe(item);
    });

    return () => {
      items.forEach(item => {
        observer.unobserve(item);
      });
    };
  }, []);

  return (
    <section id="howitworks" className="howitworks" ref={sectionRef}>
      <div className="container section-title" data-aos="fade-up">
        <h2>How it Works?</h2>
      </div>

      <div className="container">
        <div className="row gy-4">
          <div className="col-xl-5 content" data-aos="fade-up" data-aos-delay="100">
            <h3>How it Works?</h3>           
            <p>Delivery of books begins once your Membership Payment is cleared. 
              You can keep the books with you as long as you want, provided you continue to be a paying member i.e. you keep paying the monthly rental. 
              Do not get too cozy with the books and unintentionally cause any damage to them. You might have to pay for the books in that case. 
              Make sure that you order for a pickup only after you have finished reading the book. 
              Make sure that either you or anybody on your behalf is available at your delivery address to receive and return the books.</p>
            <a href="howitworks" className="read-more">
              <span>Read More</span><i className="bi bi-arrow-right"></i>
            </a>
          </div>

          <div className="col-xl-7 position-relative" data-aos="fade-up" data-aos-delay="200">
            <div className="icon-box position-relative overflow-hidden">
              <img src="assets/img/books/video_thumb.jpg" className="img-fluid" alt=""/>
              <div className="play-video">
                <div className="d-flex align-items-center justify-content-center h-100">
                  <a className=" btn fs-4 rounded-circle" href="#howitworks" onClick={() => setOpen(true)} style={{outline: 'none'}}>
                    <i className="bi bi-play-circle-fill"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isOpen} onClose={() => setOpen(false)} videoUrl="assets/videos/howitworks.mp4" />
    </section>
  );
}

export default Howworks;
