import React from "react";

const MembershipMessages = () => {
  return (
    <><br />
  
      <p className="membership-messages">
        Rest assured, our transparent pricing model ensures no hidden charges,
        with all fees inclusive of taxes. Additionally, our fully refundable security deposit offer peace of
        mind, reflecting our commitment to providing a seamless and rewarding
        reading experience for our members.
      </p>
      
    </>
  );
};

export default MembershipMessages;
