import React, { useState } from "react";
import { BookOpen, DollarSign, Users, ArrowRight, X } from "lucide-react";

// Inline styles object
const styles = {
  container: {
    // background: "linear-gradient(to bottom right, #fff7ed, #ffedd5)",
    background: "linear-gradient(145deg, rgba(244, 244, 244, 1) 0%, rgba(254, 115, 6, 0.4682247899159664) 100%)",
    padding: "4rem 1rem",
  },
  title: {
    fontSize: "2.5rem",
    fontWeight: 800,
    textAlign: "center",
    color: "#1f2937",
    marginBottom: "3rem",
  },
  advantagesGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    gap: "2rem",
    marginBottom: "3rem",
  },
  advantageCard: {
    backgroundColor: "white",
    borderRadius: "0.5rem",
    padding: "1.5rem",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    transition: "box-shadow 0.3s ease",
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "1rem",
  },
  cardTitle: {
    fontSize: "1.25rem",
    fontWeight: 600,
    textAlign: "center",
    color: "#1f2937",
    marginBottom: "0.5rem",
  },
  cardDescription: {
    textAlign: "center",
    color: "#4b5563",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    gap: "1rem",
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: "0.75rem 1.5rem",
    borderRadius: "9999px",
    fontWeight: 600,
    fontSize: "1.125rem",
    transition: "background-color 0.3s ease",
    cursor: "pointer",
  },
  primaryButton: {
    backgroundColor: "#f97316",
    color: "white",
    border: "none",
  },
  secondaryButton: {
    backgroundColor: "white",
    color: "#f97316",
    border: "2px solid #f97316",
  },
  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: "white",
    padding: "2rem",
    borderRadius: "0.5rem",
    maxWidth: "500px",
    width: "100%",
    maxHeight: "80vh",
    overflowY: "auto",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },
  modalTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  closeButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
    fontSize: "1.5rem",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  input: {
    padding: "0.5rem",
    borderRadius: "0.25rem",
    border: "1px solid #d1d5db",
  },
  submitButton: {
    backgroundColor: "#f97316",
    color: "white",
    padding: "0.75rem",
    borderRadius: "0.25rem",
    border: "none",
    cursor: "pointer",
    fontWeight: "bold",
  },
};

const PartnerAdvantageSection = () => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const advantages = [
    {
      icon: <BookOpen size={48} color="#f97316" />,
      title: "Share Your Collection",
      description:
        "Allow us to share your library's collection with eager readers across India.",
    },
    {
      icon: <DollarSign size={48} color="#f97316" />,
      title: "Effortless Income",
      description:
        "Unlock a new revenue stream with no extra work required on your part.",
    },
    {
      icon: <Users size={48} color="#f97316" />,
      title: "Contribute to a Noble Cause",
      description:
        "Shape the future of India by empowering young minds through education.",
    },
  ];

  const Modal = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    return (
      <div style={styles.modal}>
        <div style={styles.modalContent}>
          <div style={styles.modalHeader}>
            <h3 style={styles.modalTitle}>{title}</h3>
            <button style={styles.closeButton} onClick={onClose}>
              <X size={24} />
            </button>
          </div>
          {children}
        </div>
      </div>
    );
  };

  return (
    <section id="be-a-partner" style={styles.container}>
    
    <div className="container" >
      {/* <h2 style={styles.title}>Be a Partner</h2> */}
      <div className="container section-title">
        <h2>Be a Partner</h2>
      </div>
      <div style={styles.advantagesGrid}>
        {advantages.map((item, index) => (
          <div key={index} style={styles.advantageCard}>
            <div style={styles.iconWrapper}>{item.icon}</div>
            <h3 style={styles.cardTitle}>{item.title}</h3>
            <p style={styles.cardDescription}>{item.description}</p>
          </div>
        ))}
      </div>
      <div style={styles.buttonGroup}>
        <button
          style={{ ...styles.button, ...styles.primaryButton }}
          onClick={() => setIsInfoModalOpen(true)}
        >
          Know More
          <ArrowRight size={20} style={{ marginLeft: "0.5rem" }} />
        </button>
        <button
          style={{ ...styles.button, ...styles.secondaryButton }}
          onClick={() => setIsFormModalOpen(true)}
        >
          Join KalpTaru
          <ArrowRight size={20} style={{ marginLeft: "0.5rem" }} />
        </button>
      </div>

      <Modal
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
        title="Be a Partner"
      >
        <div style={{ color: "#4b5563" }}>
          <p>Dear Library Partner,</p>
          <p>
            We invite you to join us in our mission at KalpTaru to ignite the
            minds of young readers across India. By partnering with us, you'll
            play a crucial role in nurturing the intellectual growth of our
            nation's future leaders.
          </p>
          <p>
            Together, we can make knowledge more accessible, empowering every
            child to dream big and contribute to India's bright future.
          </p>
          <h4 style={{ fontWeight: "bold", marginTop: "1rem" }}>
            Partner Advantage
          </h4>
          <p>
            As a valued partner of KalpTaru, you stand to gain additional income
            effortlessly. By simply allowing us to share your library's
            collection with eager readers across India, you'll unlock a new
            revenue stream.
          </p>
          <p>
            We handle the logistics, promotion, and reader engagement, while you
            enjoy the financial benefits. Join us in shaping the future, and
            watch your library grow in more ways than one!
          </p>
        </div>
      </Modal>

      <Modal
        isOpen={isFormModalOpen}
        onClose={() => setIsFormModalOpen(false)}
        title="Join KalpTaru Today"
      >
        <form style={styles.form}>
          <input style={styles.input} placeholder="Library Name" />
          <input style={styles.input} placeholder="Contact Person" />
          <input style={styles.input} type="email" placeholder="Email" />
          <input style={styles.input} type="tel" placeholder="Phone" />
          <button style={styles.submitButton} type="submit">
            Submit
          </button>
        </form>
      </Modal>
    </div>
        
    </section>
  );
};

export default PartnerAdvantageSection;
