import React from 'react';

const DiscountPrice = ({ amount }) => {
  const discountPercentage = 10; // Define the discount percentage
  const totalMonths = 12; // Define the total number of months

  const basePrice = amount * totalMonths; // Calculate the base price
  const discountedPrice = basePrice * (1 - discountPercentage / 100); // Calculate the discounted price

  return (
    <div className='mb-3'>
      <span style={{ textDecoration: 'line-through' }}>₹{basePrice.toFixed(2)}</span>
      <span style={{ fontSize: '24px', fontWeight: 'bold' }}> ₹{discountedPrice.toFixed(2)}</span>
      <span style={{ color: 'green' }}> SAVE {discountPercentage}%**</span>
    </div>
  );
};

export default DiscountPrice;