import React from "react";
import "../../components/modal.css";

const styles = {
  hidden: {
    display: "none",
  },
};
const ForgotPassword = ({switchToLogin}) => {
  return (
    <form
      action="forms/contact.php"
      method="post"
      className="login-form"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <div className="row gy-4">
        <div className="col-md-12">
          <input
            type="email"
            name="email"
            className="form-control"
            placeholder="Enter Email or Phone"
            required
          />
        </div>
        {/* <div className="col-md-12">
          <a href="#getotp" className="link-opacity-100-hover fs-14px">
            Get OTP
          </a>
        </div> */}
        <div className="col-md-12" style={{display:'none'}}>
          <input
            type="text"
            className="form-control"
            name="otp"
            placeholder="Enter OTP"
            required
          />
        </div>

        <div className="col-md-12">
        <a href="#getotp" className="link-opacity-100-hover float-end fs-14px">
            Get OTP
          </a>
        </div>

        <div className="col-md-12 text-center">
          <div style={styles.hidden} className="loading">
            Loading
          </div>
          <div style={styles.hidden} className="error-message"></div>
          <div style={styles.hidden} className="sent-message">
            Logged in Successfully!, Hold on for a moment
          </div>

          <button type="button" disabled>Reset Password</button>
        </div>
        {/* <div className="col-md-12 text-center">
          <button type="button">Login</button>
        </div> */}

        <div className="col-md-12 text-center">
          <div>
            <a onClick={switchToLogin} href="#" className="link-opacity-100-hover">
            Back to Login
            </a>
          </div>
        </div>

        <div className="col-md-12 text-center">
          <button type="button" className="btn btn-dark rounded-pill">
            <i className="bi bi-apple fs-5"></i> Login with Apple
          </button>
        </div>

        <div className="col-md-12 text-center">
          <button type="button" className="btn btn-google rounded-pill">
            <i className="bi bi-google fs-5"></i> Login with Google{" "}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ForgotPassword;
