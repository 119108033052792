import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const faqData = [
  {
    num: '1',
    question: 'Are these e-Books?',
    answer: 'No, these aren\'t digital books. We carry physical books, just like what you find in bookstores and libraries.'
  },
  {
    num: '2',
    question: 'What are the different membership plans available?',
    answer: 'There are various plans available depending on the number of books a person wants to read and the duration of their subscription. For more information, please visit the \'Membership\' page on our website.'
  },
  {
    num: '3',
    question: 'How do I make the payment?',
    answer: 'You can make the payment through any one of the following methods: Credit card/Debit Card, UPI, and Net banking.'
  },
  {
    num: '4',
    question: 'What if the books are not delivered within 1-3 working days?',
    answer: 'Some orders might get delayed due to reasons not in our control. You can check the status of your order from our order list page. If you need further help, please reach out to us using the Support page.'
  },
  {
    num: '5',
    question: 'After I finish reading, how do I get my next set of books?',
    answer: 'After you have finished reading, please press \'Pickup and deliver\' (if you want the next book to be delivered) or just \'Pickup\' (if you do not want the next books now). We would pick up the books that you have with you and deliver the next set of books from your booklist (if you have selected for pick-up & delivery) within 1-3 working days.'
  },
  {
    num: '6',
    question: 'Can I upgrade/downgrade my membership?',
    answer: 'You can upgrade your membership in between and you will be charged for the subscription fee on a pro-rated base. You cannot downgrade a plan while it is running. The downgrade is effective only from the next billing cycle.'
  },
  {
    num: '7',
    question: 'How long can I keep the books with me?',
    answer: 'There is no limit on how long you can keep the book, as long as you are reading. We encourage the readers to return the books as soon as they complete the reading so that the other KT Community members can benefit from the reading of that book.'
  },
  {
    num: '8',
    question: 'What if I damage/lose the book?',
    answer: 'We will handle the damages and loss on a case-by-case basis. Kalptaru will assess the damages and the charges will be covered by the Deposit money.'
  },
  {
    num: '9',
    question: 'Can I cancel my membership during my membership period?',
    answer: 'Yes, you can cancel your membership at any time during your membership period. This can be done by dropping an email to support@kalptaruonline.com. Our customer care executive will call you to confirm your request and also note the reason for closure. You will have to return any books that you have with you within 5 working days failing which an amount equal to the MRP of the books would be deducted from your security deposit. Any amount left after deducting the dues from your security deposit will be given to you via an account transfer. The refund of the membership fee would be on a pro-rata basis.'
  },
  {
    num: '10',
    question: 'If I register but do not rent any books during the period, can I use them later?',
    answer: 'Membership terms cannot be changed. Your membership plan fee will be applied every month/year as per the plan, regardless of whether you rent or not.'
  },
  {
    num: '11',
    question: 'Is ‘Auto pay’ mandatory to pay subscription fees?',
    answer: 'Yes. ‘Auto Pay’ is mandatory to continue the membership.'
  },
  {
    num: '12',
    question: 'How do I see my billing details?',
    answer: 'You can access your billing information from your Account > Billing.'
  },
  {
    num: '13',
    question: 'Do I need to pay for delivery and pickup of books?',
    answer: 'No, Pickup and delivery is free.'
  },
  {
    num: '14',
    question: 'When would the books be delivered?',
    answer: 'Our goal is to dispatch the books for delivery within 24 hours from the time you place your order. However, please be aware that some unforeseen circumstances such as inclement weather or shutdowns may cause delivery delays.'
  },
  {
    num: '15',
    question: 'What if I am not available at home when the delivery happens?',
    answer: 'Don’t worry if you are not available at home and miss a delivery, we give you a call and try to arrange for delivery at a time suitable to you.'
  },
  {
    num: '16',
    question: 'How many books would be delivered in a single delivery?',
    answer: 'The maximum number of books delivered is as per your plan.'
  },
  {
    num: '17',
    question: 'How many deliveries would be made in a month?',
    answer: 'The maximum number of deliveries is as per your plan. However, you can have up to one delivery in a single day.'
  },
  {
    num: '18',
    question: 'Do I need to return both the books at the same time or can I return one book and retain the other?',
    answer: 'Yes, you can return the books as you finish reading them.'
  },
  {
    num: '19',
    question: 'What if the books sent to me are not in good condition? / What do I do if I get a wrong book?',
    answer: 'Our team does a book quality check before they attempt a delivery. However, if you find that the books sent are not in good condition, then we please reach out to the support at support@kalptaruonline.com. We will address the issue as soon as possible.'
  },
  {
    num: '20',
    question: 'What are the different options of requesting a pickup?',
    answer: 'Visit your Account -> Orders and request for \'Pickup\'.'
  },
  {
    num: '21',
    question: 'What is Suggest a Book?',
    answer: 'If there is a book that you want to read, and it is not available in our collection, then you can request for the book to be included in our library. We will try to get it as soon as possible.'
  },
  {
    num: '22',
    question: 'Will the same person who would come for pickup be delivery the next book?',
    answer: 'The pickup & delivery could be done by the same or different people based on staff availability.'
  },
  {
    num: '23',
    question: 'How do I rent books?',
    answer: 'Please refer to the \'How it Works\' page.'
  },
  {
    num: '24',
    question: 'How do I change my contact details?',
    answer: 'Go to My Account > Edit Details to change your contact details.'
  },
  {
    num: '25',
    question: 'How can I change my delivery address?',
    answer: 'You can manage your delivery addresses from your profile.'
  },
  {
    num: '26',
    question: 'How can I change my password?',
    answer: 'Go to My Account > Password. You are requested to kindly change your default password after first login.'
  },
  {
    num: '27',
    question: 'What if I forget my password?',
    answer: 'In the Login section, there would a \'Forgot Password\' link. Just click on that and enter your registered email address/Login Id. Your password would be mailed to your email address.'
  },
  {
    num: '28',
    question: 'Is my credit card data safe?',
    answer: 'Our application does not gather your credit card, debit card, or bank account information. Upon clicking the \'Pay now\' button, you\'ll be directed to the Cashfree secure gateway for payment processing. Cashfree stands as India\'s foremost payment gateway. Upon completing the payment procedure, you will be redirected back to kalptaruonline.com.'
  }
];


const Newfaq = () => {
  const contentRef = useRef(null);
  const itemRefs = useRef([]);

  useEffect(() => {
    const observerItems = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-on-scroll');
        }
      });
    }, { root: null, rootMargin: '0px', threshold: 0.1 });

    itemRefs.current.forEach((itemRef) => {
      if (itemRef) {
        observerItems.observe(itemRef);
      }
    });

    const content = contentRef.current;
    const observerContent = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          content.classList.add('animate-on-scroll');
        }
      });
    });

    observerContent.observe(content);

    return () => {
      observerItems.disconnect();
      observerContent.disconnect();
    };
  }, []);

  return (

<>
    
    <section id="faq" className="faq" ref={contentRef}>
      <div className="container">
      <div
          className="container section-title"
          data-aos="fade-up"
        >
          <h2>FAQ</h2>
        </div>
        <div className="row gy-4">
         
          <div className="col-lg-12" data-aos="fade-up" data-aos-delay="200" ref={(el) => (itemRefs.current[1] = el)}>
            <div className="faq-container" style={{padding:0}}>
              {faqData.map((item, index) => (
                <FaqItem
                  key={index}
                  num={item.num}
                  question={item.question}
                  answer={item.answer}
                  isOpenInitially={index === 0}
                  ref={(el) => (itemRefs.current[index + 2] = el)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

const FaqItem = React.forwardRef(({ num, question, answer, isOpenInitially = false }, ref) => {
  const [isOpen, setIsOpen] = useState(isOpenInitially);
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setHeight(isOpen ? 0 : contentRef.current.scrollHeight);
  };

  useEffect(() => {
    if (isOpenInitially) {
      setHeight(contentRef.current.scrollHeight);
    }
  }, [isOpenInitially]);

  return (
    <div className={`faq-item ${isOpen ? 'faq-active' : ''}`} ref={ref}>
      <h3 className="faq-title" onClick={toggleAccordion}>
        <span className="num">{num}.</span> <span>{question}</span>
        <i className={`faq-toggle bi ${isOpen ? 'bi-chevron-down' : 'bi-chevron-right'}`}></i>
      </h3>
      <div className="" style={{ height: `${height}px`, overflow: 'hidden', transition: 'height 0.3s ease-in-out' }} ref={contentRef}>
        <p>{answer}</p>
      </div>
    </div>
  );
});

FaqItem.propTypes = {
  num: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  isOpenInitially: PropTypes.bool,
};

FaqItem.defaultProps = {
  isOpenInitially: false,
};

export default Newfaq;