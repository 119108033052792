import React, { useState, useEffect, useRef } from "react";
import HeaderN from "../components/HeaderN";
// import Aboutfaq from "../components/faq/Aboutfaq";
// import Basicinfo from "../components/faq/Basicinfo";
// import PaymentFaq from "../components/faq/PaymentFaq";
// import DeliveryFaq from "../components/faq/DeliveryFaq";
// import SpecialFAQ from "../components/faq/SpecialFAQ";
// import SecurityFaq from "../components/faq/SecurityFaq";
import Newfaq from "../components/faq/Newfaq";
import FooterN from "../components/FooterN";
import useScrolledClass from "../components/useScrolledClass";
import useAnimateOnScroll from "../components/useAnimateOnScroll";
import Preloader from "../components/Preloader";
import LoginCard from "../components/login/LoginCard";
import WhatsAppIcon from "../components/StickyWhatsapp/WhatsappIcon";

const FAQ = () => {
  useScrolledClass();

  const mainRef = useRef(null);
  const elementRefs = useRef([]);
  const observeElement = useAnimateOnScroll();

  useEffect(() => {
    if (mainRef.current) {
      observeElement(mainRef.current);
    }

    elementRefs.current.forEach((ref) => {
      if (ref) {
        observeElement(ref);
      }
    });
  }, [observeElement]);

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    if (showModal) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }

    return () => {
      body.style.overflow = "auto";
    };
  }, [showModal]);

  return (
    <>
      <div className={showModal ? "blur" : ""}>
        <HeaderN toggleModal={toggleModal} />
        <main>
          <section
            id="hero"
            className="banners aboutus"
            ref={(el) => (elementRefs.current[19] = el)}
          >
            <div className="banner-item">
              <img
                className="banner-img"
                src="assets/img/FaqDesigner.png"
                alt=""
                data-aos="fade-in"
                ref={(el) => (elementRefs.current[20] = el)}
              />
              <div
                className="d-flex align-items-center justify-content-center h-100 "
                data-aos="fade-up"
                data-aos-delay="200"
                ref={(el) => (elementRefs.current[0] = el)}
              >
                <div className="container text-center about-content">
                  <div>
                    <h2
                      className="banner-header-text "
                      data-aos="fade-up"
                      data-aos-delay="100"
                      ref={(el) => (elementRefs.current[1] = el)}
                    >
                      Frequently Asked Questions
                    </h2>
                  </div>
                  <p
                    data-aos="fade-up"
                    data-aos-delay="200"
                    ref={(el) => (elementRefs.current[2] = el)}
                  >
                    <i className="bi bi-quote quote-icon-left"></i>
                    <span>
                      {" "}
                      Your one-stop shop for all questions on renting and
                      memberships. Still puzzled? Get in touch for assistance!
                    </span>
                    <i className="bi bi-quote quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="container faq-container">
            <Newfaq />
          </section>
        </main>
        <FooterN />
      </div>
      <Preloader />
      {showModal && (
        <div className="modal-overlay modal-overlay-bg">
          <LoginCard
           toggleModal={toggleModal} />
        </div>
      )}
      <WhatsAppIcon/>
    </>
  );
};

export default FAQ;
