import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Faq = () => {
  // Animation code
  const contentRef = useRef(null);
  const itemRefs = useRef([]);

  useEffect(() => {
    const observerItems = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-on-scroll");
        }
      });
    }, { root: null, rootMargin: "10px", threshold: 0.1 });

    itemRefs.current.forEach((itemRef) => {
      if (itemRef) {
        observerItems.observe(itemRef);
      }
    });



    const content = contentRef.current;
    const observerContent = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          content.classList.add("animate-on-scroll");
        }
      });
    });

    observerContent.observe(content);

    return () => {
      observerItems.disconnect();
      observerContent.disconnect();
    };
  }, []);
  useEffect(() => {
    if (window.location.hash === '#faq') {
      const element = document.getElementById('faq');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);


  return (
    <section id="faq" className="faq" ref={contentRef}>
      <div className="container">
        <div className="row gy-4">
          <div
            className="col-lg-4"
            data-aos="fade-up"
            data-aos-delay="100"
            style={{ display: 'flex', alignItems: 'center' }}
            ref={(el) => (itemRefs.current[0] = el)}
          >
            <div className="content px-xl-5">
              <h3 style={{textAlign:'center'}}>
                <span>Frequently Asked </span>
                <strong>Questions</strong>
              </h3>
              <p>
              Welcome to our Frequently Asked Questions (FAQ) section, designed to address your most common queries about book donations. Here, you'll find answers to our top 5 frequently asked questions:
              </p>
              <a href="faq" className="read-more">
              <span>Read More </span><i className="bi bi-arrow-right"></i>
            </a>
            </div>
          </div>
          <div className="col-lg-8" data-aos="fade-up" data-aos-delay="200" ref={(el) => (itemRefs.current[1] = el)}>
            <div className="faq-container">
              <FaqItem
                num="1"
                question="Are these e-Books?"
                answer="No, these aren't digital books. We carry physical books, just like what you find in bookstores and libraries."
                isOpenInitially={true}
                ref={(el) => (itemRefs.current[2] = el)}
              />
              <FaqItem
                num="2"
                question="What are the different membership plans available?"
                answer="There are various plans available depending on the number of books a person wants to read and the duration of their subscription. For more information, please visit the 'Membership' page on our website."
                ref={(el) => (itemRefs.current[3] = el)}
              />
              <FaqItem
                num="3"
                question="How do I make the payment?"
                answer="You can make the payment through any one of the following methods: Credit card/Debit Card, UPI, and Net banking."
                ref={(el) => (itemRefs.current[4] = el)}
              />
              <FaqItem
                num="4"
                question="What if the books are not delivered within 1-3 working days?"
                answer="Some orders might get delayed due to reasons not in our control. You can check the status of your order from our order list page. If you need further help, please reach out to us using the Support page."
                ref={(el) => (itemRefs.current[5] = el)}
              />
              <FaqItem
                num="5"
                question="After I finish reading, how do I get my next set of books?"
                answer="After you have finished reading, please press 'Pickup and deliver' (if you want the next book to be delivered) or just 'Pickup' (if you do not want the next books now). We would pick up the books that you have with you and deliver the next set of books from your booklist (if you have selected for pick-up & delivery) within 1-3 working days."
                ref={(el) => (itemRefs.current[6] = el)}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const FaqItem = React.forwardRef(({ num, question, answer, isOpenInitially = false }, ref) => {
  const [isOpen, setIsOpen] = useState(isOpenInitially);
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setHeight(isOpen ? 0 : contentRef.current.scrollHeight);
  };

  useEffect(() => {
    if (isOpenInitially) {
      setHeight(contentRef.current.scrollHeight);
    }
  }, [isOpenInitially]);

  return (
    <div className={`faq-item ${isOpen ? 'faq-active' : ''}`} ref={ref}>
      <h3 className="faq-title" onClick={toggleAccordion}>
        <span className="num">{num}.</span> <span>{question}</span>
        <i className={`faq-toggle bi ${isOpen ? 'bi-chevron-down' : 'bi-chevron-right'}`}></i>
      </h3>
      <div className="" style={{ height: `${height}px`, overflow: 'hidden', transition: 'height 0.3s ease-in-out' }} ref={contentRef}>
        <p>{answer}</p>
      </div>
    </div>
  );
});

FaqItem.propTypes = {
  num: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  isOpenInitially: PropTypes.bool,
};

FaqItem.defaultProps = {
  isOpenInitially: false,
};

export default Faq;