import { useParams } from "react-router-dom";
import Header from "../components/Header";
import LoginForm from "../components/LoginForm";
import SignupForm from "../components/SignupForm";
import Footer from "../components/Footer";

const Login = () => {
  const url = window.location.pathname.split("/").pop();

  return (
    <>
      <Header />
      <div className="container-fluid mt-3">
        <div className="login-container">
          <div className="login-Form-left">
          {url === "signup" ? <SignupForm /> : <LoginForm />}
          </div>
          <div className="login-image-right">
            <img src="/imagespu/work.png" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
