import React, { useState } from 'react';

const Donate = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    city: '',
    state: '',
    address: '',
  });

  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });
  
    try {
      const response = await fetch('https://kalptaruonline.com/php_api/donate_api.php', {
        method: 'POST',
        body: formDataToSend,
      });
  
      if (response.ok) {
        const data = await response.json();
        setPopupMessage(data.message);
        setShowPopup(true);
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          city: '',
          state: '',
          address: '',
        });
      } else {
        const errorData = await response.json();
        setPopupMessage(errorData.message || 'Oops, there was an error submitting your book donation request. Please try again later.');
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setPopupMessage('Oops, there was an error submitting your book donation request. Please try again later.');
      setShowPopup(true);
    }
  };

  return (
    <section id="donate" className="category donate">
      <style>
        {`
          .popup {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #FFF;
            padding: 40px;
            border-radius: 8px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            z-index: 999;
            text-align: center;
            animation: popupAnimation 0.5s ease-in-out;
          }
          .popup h3 {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 20px;
            color: #007bff;
          }
          .popup button {
            background-color: #007bff;
            color: #FFF;
            border: none;
            padding: 10px 20px;
            border-radius: 4px;
            cursor: pointer;
            font-size: 16px;
            transition: background-color 0.3s ease;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }
          .popup button:hover {
            background-color: #0056b3;
          }
          @keyframes popupAnimation {
            0% {
              opacity: 0;
              transform: translate(-50%, -60%);
            }
            100% {
              opacity: 1;
              transform: translate(-50%, -50%);
            }
          }
        `}
      </style>
      <div className="container section-title" data-aos="fade-up">
        <h2>Donate a Book</h2>
      </div>
      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4">
          <div className="col-lg-5 content" data-aos="fade-up">
            <h2 className="text-accent">Why Donate?</h2>
            <p>By donating books, you give others the opportunity to learn and grow. Your old books could be someone else's treasure, helping them expand their knowledge and imagination.</p>
          </div>
          <div className="col-lg-7">
            <form onSubmit={handleSubmit} className="php-email-form no-bg" data-aos="fade-up" data-aos-delay="200">
              <div className="row gy-4">
                {/* First Name */}
                <div className="col-md-6">
                  <input type="text" name="firstName" className="form-control" placeholder="Your First Name" value={formData.firstName} onChange={handleChange} required />
                </div>
                {/* Last Name */}
                <div className="col-md-6">
                  <input type="text" name="lastName" className="form-control" placeholder="Your Last Name" value={formData.lastName} onChange={handleChange} required />
                </div>
                {/* Email */}
                <div className="col-md-6">
                  <input type="email" name="email" className="form-control" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
                </div>
                {/* Phone */}
                <div className="col-md-6">
                  <input type="text" name="phone" className="form-control" placeholder="Your Phone" value={formData.phone} onChange={handleChange} required />
                </div>
                {/* City */}
                <div className="col-md-6">
                  <input type="text" name="city" className="form-control" placeholder="Your City" value={formData.city} onChange={handleChange} required />
                </div>
                {/* State */}
                <div className="col-md-6">
                  <input type="text" name="state" className="form-control" placeholder="Your State" value={formData.state} onChange={handleChange} required />
                </div>
                {/* Address */}
                <div className="col-md-12">
                  <textarea className="form-control" name="address" rows="6" placeholder="Address" value={formData.address} onChange={handleChange} required />
                </div>
                {/* Submit Button */}
                <div className="col-md-12 text-center">
                  <button type="submit">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="popup">
          <h3>{popupMessage}</h3>
          <button onClick={() => setShowPopup(false)}>Close</button>
        </div>
      )}
    </section>
  );
};

export default Donate;