import React, { useEffect, useRef } from "react";
import useAnimateOnScroll from "./useAnimateOnScroll";

const AboutSection = () => {
  const visionStyles = {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    /* Additional styles if needed */
  };

  const mainRef = useRef(null);
  const elementRefs = useRef([]);
  const observeElement = useAnimateOnScroll();

  useEffect(() => {
    if (mainRef.current) {
      observeElement(mainRef.current);
    }

    elementRefs.current.forEach((ref) => {
      if (ref) {
        observeElement(ref);
      }
    });
  }, [observeElement]);

  return (
    <main id="main" ref={mainRef} className="">
      <section
        id="hero"
        className="banners aboutus"
        ref={(el) => (elementRefs.current[19] = el)}
      >
        <div className="banner-item">
          <img
            className="banner-img"
            src="assets/img/team-header.jpg"
            alt=""
            data-aos="fade-in"
            ref={(el) => (elementRefs.current[20] = el)}
          />
          <div
            className="d-flex align-items-center justify-content-center h-100 "
            data-aos="fade-up"
            data-aos-delay="200"
            ref={(el) => (elementRefs.current[0] = el)}
          >
            <div className="container text-center about-content">
              <div>
                <h2
                  className="banner-header-text "
                  data-aos="fade-up"
                  data-aos-delay="100"
                  ref={(el) => (elementRefs.current[1] = el)}
                >
                  About Kalptaru
                </h2>
              </div>
              <p
                data-aos="fade-up"
                data-aos-delay="200"
                ref={(el) => (elementRefs.current[2] = el)}
              >
                <i className="bi bi-quote quote-icon-left"></i>
                <span> Nurturing Minds, Cultivating futures </span>
                <i className="bi bi-quote quote-icon-right"></i>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        id="about-kt"
        className="about-kt "
        ref={(el) => (elementRefs.current[21] = el)}
      >
        <div
          className="container section-title "
          data-aos="fade-up"
          ref={(el) => (elementRefs.current[3] = el)}
        >
          <h2>About Us</h2>
        </div>

        <div
          className="container "
          ref={(el) => (elementRefs.current[22] = el)}
        >
          <div className="row gy-4">
            <div
              className="col-xl-6 content "
              data-aos="fade-up"
              data-aos-delay="100"
              ref={(el) => (elementRefs.current[4] = el)}
            >
              <p ref={(el) => (elementRefs.current[23] = el)}>
                KalpTaru is dedicated to fostering a love for reading among
                youth, drawing inspiration from ancient Indian traditions of
                learning. Through curated book selections and innovative
                programs, we aim to cultivate critical thinking and creativity,
                empowering individuals to navigate the complexities of the
                modern world. Our vision includes expanding our reach nationwide
                and globally, leveraging technology and partnerships to drive
                positive social change. As we celebrate India's 100 years of
                independence, we aspire to contribute to a society where access
                to quality education and literature transforms lives, leaving a
                legacy of empowerment and enlightenment.
              </p>

              <p>
                KalpTaru is a passionate endeavor driven by a group of
                enthusiastic Indians determined to revive India's culture of
                reading and restore its historical prominence as the Vishwaguru,
                or the world's preeminent source of knowledge. Our mission is to
                reignite the love for reading across the nation and empower
                individuals with the wisdom and enlightenment found within
                books.
              </p>

              <p>
                With a deep-rooted belief in the transformative power of
                literature, KalpTaru aims to cultivate a society where every
                individual values the pursuit of knowledge and intellectual
                growth. Through innovative programs, partnerships, and digital
                platforms, we aspire to create a nationwide movement that
                celebrates reading as a cornerstone of personal development and
                societal progress.
              </p>

              <p>
                Join us on this transformative journey as we work towards
                realizing our vision of a nation where reading is revered,
                knowledge is cherished, and India shines once again as the
                Vishwaguru of the world. Together, let us embark on a quest to
                unlock the limitless potential of every individual and
                collectively contribute to India's renaissance as a beacon of
                wisdom and enlightenment for humanity.
              </p>
            </div>

            <div
              className="col-xl-6 position-relative "
              data-aos="fade-up"
              data-aos-delay="200"
              ref={(el) => (elementRefs.current[5] = el)}
              style={visionStyles}
            >
              <div
                className="icon-box position-relative overflow-hidden"
                style={{ width: "100%" }}
              >
                <img
                  src="assets/img/about-svg.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="mission" className="mission">
        <div
          className="container section-title "
          data-aos="fade-up"
          ref={(el) => (elementRefs.current[6] = el)}
        >
          <h2>Mission</h2>
        </div>
        <div className="container">
          <div className="row gy-4">
            <div
              className="col-xl-6 position-relative "
              data-aos="fade-up"
              data-aos-delay="200"
              ref={(el) => (elementRefs.current[7] = el)}
            >
              <div className="icon-box position-relative overflow-hidden">
                <img
                  src="assets/img/mission-svg.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>

            <div
              className="col-xl-6 content "
              data-aos="fade-up"
              data-aos-delay="100"
              ref={(el) => (elementRefs.current[8] = el)}
            >
              <p>
                At KalpTaru, our mission is to ignite a passion for reading
                among young minds and empower them with the transformative
                knowledge found within books. Inspired by the ancient Indian
                tradition of learning from gurus, we strive to create a
                nurturing environment where children and young adults can
                discover the joy of reading and embark on a journey of lifelong
                learning. Through our innovative programs and curated selection
                of books, we aim to cultivate critical thinking, creativity, and
                empathy, fostering the growth of well-rounded individuals who
                are equipped to navigate the complexities of the modern world.
                At KalpTaru, we believe that every book has the power to shape
                minds, inspire dreams, and create positive change, and we are
                committed to making the world a brighter place, one reader at a
                time.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="vision" className="vision">
        <div
          className="container section-title  pb-0"
          data-aos="fade-up"
          ref={(el) => (elementRefs.current[9] = el)}
        >
          <h2>Our Vision</h2>
        </div>

        <div className="container">
          <div className="row gy-4">
            <div
              className="col-xl-6 content  mt-0"
              data-aos="fade-up"
              data-aos-delay="100"
              ref={(el) => (elementRefs.current[10] = el)}
              style={visionStyles}
            >
              <p>
                In the next five years, KalpTaru aims to be a literary
                cornerstone, reaching every corner of India and beyond,
                nurturing avid readers and learners. By fostering a reading
                culture, we empower young minds for the 21st century.
              </p>
              <p>
                As we look ahead to India's 100th year of independence in 2047,
                KalpTaru remains steadfast in its dedication to fostering
                inclusivity and diversity within our educational initiatives. We
                strive to create a society where every individual, regardless of
                background or circumstance, has equal access to the wealth of
                knowledge and opportunities available. Leveraging technology,
                KalpTaru is committed to building bridges of understanding,
                empathy, and cooperation, ensuring that every citizen
                contributes to and benefits from the collective advancement of
                our great nation.
              </p>
            </div>

            <div
              className="col-xl-6 position-relative  mt-0"
              data-aos="fade-up"
              data-aos-delay="200"
              ref={(el) => (elementRefs.current[11] = el)}
            >
              <div className="icon-box position-relative overflow-hidden">
                <img
                  src="assets/img/vission-svg.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="team" className="team mission">
        <div
          className="container section-title "
          data-aos="fade-up"
          ref={(el) => (elementRefs.current[12] = el)}
        >
          <h2>Team</h2>
          <p>
          Meet our proactive team member spearheading our online book rental service.
          </p>
        </div>

        <div className="container">
        <div className="row gy-5">
            <div
              className="col-lg-4 col-md-6 member "
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="member-img">
                <img
                  src="assets/img/team/Prakash.png"
                  className="img-fluid"
                  alt=""
                />
                <div className="social">
                  <a href="#">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>
              <div className="member-info text-center">
                <h4>Prakash Gupta</h4>
                <span>Corporate Team</span>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 member "
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="member-img">
                <img
                  src="assets/img/team/shweta.png"
                  className="img-fluid"
                  alt=""
                />
                <div className="social">
                  <a href="#">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>
              <div className="member-info text-center">
                <h4>Shweta Magar</h4>
                <span>Platform Team</span>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 member "
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="member-img">
                <img
                  src="assets/img/team/Rishi_singh.png"
                  className="img-fluid"
                  alt=""
                />
                <div className="social">
                  <a href="#">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>
              <div className="member-info text-center">
                <h4>Rishi Singh</h4>
                <span>Sales Team</span>
              </div>
            </div>

            {/* <div className="col-lg-4 col-md-6 member " data-aos="fade-up" data-aos-delay="400" ref={(el) => (itemRefs.current[4] = el)}>
              <div className="member-img">
                <img src="assets/img/team/team-4.jpg" className="img-fluid" alt="" />
                <div className="social">
                  <a href="#"><i className="bi bi-twitter"></i></a>
                  <a href="#"><i className="bi bi-facebook"></i></a>
                  <a href="#"><i className="bi bi-instagram"></i></a>
                  <a href="#"><i className="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div className="member-info text-center">
                <h4>Amanda Jepson</h4>
                <span>Accountant</span>
              </div>
            </div> */}
            {/* 
            <div className="col-lg-4 col-md-6 member " data-aos="fade-up" data-aos-delay="500" ref={(el) => (itemRefs.current[5] = el)}>
              <div className="member-img">
                <img src="assets/img/team/team-5.jpg" className="img-fluid" alt="" />
                <div className="social">
                  <a href="#"><i className="bi bi-twitter"></i></a>
                  <a href="#"><i className="bi bi-facebook"></i></a>
                  <a href="#"><i className="bi bi-instagram"></i></a>
                  <a href="#"><i className="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div className="member-info text-center">
                <h4>Brian Doe</h4>
                <span>Marketing</span>
                <p>Qui consequuntur quos accusamus magnam quo est molestiae eius laboriosam sunt doloribus quia impedit laborum velit</p>
              </div>
            </div>

            

    <div class="col-lg-4 col-md-6 member " data-aos="fade-up" data-aos-delay="600"  ref={(el) => (itemRefs.current[6] = el)}>
      <div class="member-img">
        <img src="assets/img/team/team-6.jpg" class="img-fluid" alt=""/>
        <div class="social">
          <a href="#"><i class="bi bi-twitter"></i></a>
          <a href="#"><i class="bi bi-facebook"></i></a>
          <a href="#"><i class="bi bi-instagram"></i></a>
          <a href="#"><i class="bi bi-linkedin"></i></a>
        </div>
      </div>
      <div class="member-info text-center">
        <h4>Josepha Palas</h4>
        <span>Operation</span>
        <p>Sint sint eveniet explicabo amet consequatur nesciunt error enim rerum earum et omnis fugit eligendi cupiditate vel</p>
      </div>
    </div> */}
          </div>
        </div>
      </section>
    </main>
  );
};
export default AboutSection;
