import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeaderN from "../components/HeaderN";
import Preloader from "../components/Preloader";
import useScrolledClass from "../components/useScrolledClass";
import HeroSection from "../components/homepage/HeroSection";
import FooterN from "../components/FooterN";
import About from "../components/homepage/About";
import Stats from "../components/homepage/Stats";
import Howworks from "../components/homepage/Howworks";
import BookList from "../components/homepage/bookslist/BookList";
import Membership from "../components/homepage/membership/Membership";
import Faq from "../components/homepage/faq/Faq";
import Team from "../components/homepage/team/Team";
import AppDownload from "../components/homepage/appstore/AppDownload";
import Contact from "../components/homepage/Contact";
import Donate from "../components/homepage/Donate";
import LoginCard from "../components/login/LoginCard";
import WhatsAppIcon from "../components/StickyWhatsapp/WhatsappIcon";
import PartnerAdvantageSection from "../components/homepage/PartnerAdvantageSection";

const Home = () => {
  const location = useLocation();
  const isAboutPage = location.pathname === "/";
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    if (showModal) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }

    return () => {
      body.style.overflow = "auto";
    };
  }, [showModal]);

  useScrolledClass();


  return (
    <>
      <div className={showModal ? "blur" : ""}>
        <HeaderN toggleModal={toggleModal} />
        <main id="main">
          <HeroSection />
          <About />
          {/* <Stats /> */}
          <Howworks />
          <BookList />
          <Membership />
          <PartnerAdvantageSection/>
          <Donate />
          <Faq />
          <Team />
          <AppDownload />
          <Contact />
        </main>
        <FooterN />
        <Preloader />
      </div>
      {showModal && (
        <div className="modal-overlay modal-overlay-bg">
          <LoginCard toggleModal={toggleModal} />
        </div>
      )}
      <WhatsAppIcon/>
    </>
  );
};

export default Home;