import React from "react";
import "../../components/modal.css";

const Login = ({ styles, switchToSignup, switchToForgotPassword }) => {
  return (
    <form
      action="forms/contact.php"
      method="post"
      className="login-form"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <div className="row gy-4">
        <div className="col-md-12">
          <input
            type="email"
            name="email"
            className="form-control"
            placeholder="Email"
            required
          />
        </div>

        <div className="col-md-12">
          <input
            type="password"
            className="form-control"
            name="email"
            placeholder="Password"
            required
          />
        </div>

        <div className="col-md-12">
          <a onClick={switchToForgotPassword} href="#" className="link-opacity-100-hover float-end fs-14px">
            Forgot Password?
          </a>
        </div>

        <div className="col-md-12 text-center">
          <div style={styles.hidden} className="loading">
            Loading
          </div>
          <div style={styles.hidden} className="error-message"></div>
          <div style={styles.hidden} className="sent-message">
            Logged in Successfully!, Hold on for a moment
          </div>

          <button type="button">Login</button>
        </div>

        <div className="col-md-12 text-center">
          <div>
            Don't have an Account?{" "}
            <a onClick={switchToSignup} href="#" className="link-opacity-100-hover fs-14px">
              Sign up
            </a>
          </div>
        </div>

        <div className="col-md-12 text-center">
          <button type="button" className="btn btn-dark rounded-pill">
            <i className="bi bi-apple fs-5"></i> Login with Apple
          </button>
        </div>

        <div className="col-md-12 text-center">
          <button type="button" className="btn btn-google rounded-pill">
            <i className="bi bi-google fs-5"></i> Login with Google{" "}
          </button>
        </div>
      </div>
    </form>
  );
};

export default Login;
