import React, { useEffect, useRef } from "react";
import "./about.css";

const About = () => {
  const firstBoxRef = useRef(null);
  const secondBoxRef = useRef(null);
  const thirdBoxRef = useRef(null);
  const fourthBoxRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-on-scroll");
          }else {
            entry.target.classList.remove("animate-on-scroll");
          }
        });
      },
      { threshold: 0.02 }
    ); // Adjust threshold as needed

    const observeBox = (ref) => {
      if (ref.current) observer.observe(ref.current);
    };

    observeBox(firstBoxRef);
    observeBox(secondBoxRef);
    observeBox(thirdBoxRef);
    observeBox(fourthBoxRef);

    // Cleanup function
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (window.location.hash === '#about') {
      const element = document.getElementById('about');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row align-items-xl-center gy-5">
          <div className="col-xl-5 content">
            <h3 className="">About Us</h3>
            <h2>Fostering love for book reading</h2>
            <p ref={firstBoxRef} className="box">
              <b className="text-accent">KalpTaru</b> is dedicated to fostering
              a love for reading among youth, drawing inspiration from ancient
              Indian traditions of learning. Through curated book selections and
              innovative programs, we aim to cultivate critical thinking and
              creativity, empowering individuals...
            </p>
            <a href="about" className="read-more">
              <span>Read More</span>
              <i className="bi bi-arrow-right"></i>
            </a>
          </div>

          <div  className="col-xl-7">
            <div className="row gy-4 icon-boxes">
              <div ref={secondBoxRef} className="col-md-6 box" data-aos="fade-up" data-aos-delay="200">
                <div className="icon-box">
                  <i className="bi bi-book"></i>
                  <h3>Magical literature @home</h3>
                  <p>Bringing the magic of literature at your home</p>
                </div>
              </div>
              {/* <!-- End Icon Box --> */}

              <div ref={thirdBoxRef} className="col-md-6 box" data-aos="fade-up" data-aos-delay="300">
                <div className="icon-box">
                  <i className="bi bi-eyeglasses"></i>
                  <h3>Reads delivered @doorstep</h3>
                  <p>Discovering new reads delivered right at your door</p>
                </div>
              </div>
              {/* <!-- End Icon Box --> */}

              <div ref={fourthBoxRef} className="col-md-6 box" data-aos="fade-up" data-aos-delay="400">
                <div className="icon-box">
                  <i className="bi bi-flower3"></i>
                  <h3>Root & Mind blossom</h3>
                  <p>Where dreams take root and mind blossom</p>
                </div>
              </div>
              {/* <!-- End Icon Box -->             */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
